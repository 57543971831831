import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { ElMessage } from 'element-plus';
import { useAuthStore } from '@/store/auth';
import type { ErrorResponse } from '@/models/common'

export const errorResponseInterceptor = (error: AxiosError<ErrorResponse>) => {
  if (error?.response?.status === axios.HttpStatusCode.Unauthorized) {
    useAuthStore().clearUser();
    return Promise.resolve();
  }

  Sentry.captureException(error);

  if (error?.response?.data?.details) {
    ElMessage.error(error.response.data.details);
  }

  return Promise.reject(error);
}
