<template lang="pug">
el-card
  el-table(
    :data="reportsStore.typesReport"
    v-loading="loading"
    table-layout="auto"
    @sort-change="handleSortChange"
  )
    el-table-column(prop="label" label="Тип")
    el-table-column(prop="created" align="left" label="Создано" v-slot="scope" sortable)
      div(v-if="(scope.row.created instanceof Object)")
        el-space(direction="vertical" alignment="left")
          el-text Задач: <b>{{ scope.row.created.tasksCount || 0 }}</b>
          el-text Исследований: <b>{{ scope.row.created.researchesCount || 0 }}</b>
          el-text Брак: <b>{{ scope.row.created.unusablesCount || 0 }}</b>
    el-table-column(prop="performed" align="left" label="Размечено" v-slot="scope" sortable)
      div(v-if="(scope.row.performed instanceof Object)")
        el-space(direction="vertical" alignment="left")
          el-tooltip(trigger="hover" placement="top" raw-content :content="getPerformedTasksDetails(scope.row)")
              el-text Задач: <b>{{ scope.row.performed.tasksCount || 0 }}</b>
          el-text Исследований: <b>{{ scope.row.performed.researchesCount || 0 }}</b>
          el-text Брак: <b>{{ scope.row.performed.unusablesCount || 0 }}</b>
    el-table-column(prop="validated" align="left" label="Провалидировано" v-slot="scope" sortable)
      div(v-if="(scope.row.validated instanceof Object)")
        el-space(direction="vertical" alignment="left")
          el-text Задач: <b>{{ scope.row.validated.tasksCount || 0 }}</b>
          el-text Исследований: <b>{{ scope.row.validated.researchesCount || 0 }}</b>
          el-text Брак: <b>{{ scope.row.validated.unusablesCount || 0 }}</b>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { ElMessage } from 'element-plus'

import type { ReportsListQueryParams, TypesReportInfo } from '../models'
import { useReportsStore } from '../store/reports'

const reportsStore = useReportsStore()
const loading = ref(false)
const orderBy = ref<string | undefined>('')

const props = defineProps<{
    params: ReportsListQueryParams
}>()

watch(
  () => props.params,
  (_: ReportsListQueryParams) => update(),
  { deep: true }
)


const handleSortChange = ({ prop, order }: { prop: string, order?: string }) => {
  prop = prop.split('.')[0]
  orderBy.value = !!order ? (order === 'ascending' ? '' : '-') + prop : undefined;

  update()
}

const getPerformedTasksDetails = (data: TypesReportInfo) => {
  return `
      Принято: ${data.confirmed?.tasksCount || 0}<br/>
      Отклонено: ${data.declined?.tasksCount || 0}
  `
}

const update = () => {
  loading.value = true

  reportsStore
    .getTypesReport({
      periodStart: props.params.periodStart,
      periodEnd: props.params.periodEnd,
      orderBy: orderBy.value,
    })
    .catch(() => ElMessage.error('Ошибка при получении отчетов'))
    .finally(() => loading.value = false);
}

update()
</script>
