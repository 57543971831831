import { defineStore } from 'pinia'
import { HttpStatusCode, isAxiosError } from 'axios'
import axios from '@/utils/axios'

type Event = {
  key: string
  label: string
}

type NotificationChannel = {
  deliver_with_sound_time_start: string
  deliver_with_sound_time_end: string
  subscribed_events: string[]
}

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    configured: undefined as boolean | undefined,
    events: [] as Event[],

    soundTimeStart: undefined as string | undefined,
    soundTimeEnd: undefined as string | undefined,
    subscribedEvents: [] as string[],
  }),

  actions: {
    async getConfigurationLink(): Promise<string> {
      return (await axios.get('/notificator/bot-start-link')).data
    },

    async getAvailableEvents() {
      const { data } = await axios.get<Event[]>('/notificator/available-events')
      this.events = data
    },

    async getNotificationsSettings() {
      const resp = await axios.get<NotificationChannel>(
        '/notificator/notification-channels',
        { validateStatus: status => [HttpStatusCode.Ok, HttpStatusCode.NotFound].includes(status) },
      )

      if (resp.status === HttpStatusCode.NotFound) {
        this.configured = false
        return
      }

      this.configured = true

      this.soundTimeStart = resp.data.deliver_with_sound_time_start
      this.soundTimeEnd = resp.data.deliver_with_sound_time_end
      this.subscribedEvents = resp.data.subscribed_events
    },

    async saveNotificationsSettings() {
      const { data } = await axios.post<NotificationChannel>(
        '/notificator/notification-channels',
        {
          subscribed_events: this.subscribedEvents,
          deliver_with_sound_time_start: this.soundTimeStart,
          deliver_with_sound_time_end: this.soundTimeEnd,
        }
      )

      this.subscribedEvents = data.subscribed_events
      this.soundTimeStart = data.deliver_with_sound_time_start
      this.soundTimeEnd = data.deliver_with_sound_time_end
    },
  },
})
