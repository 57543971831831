<template lang="pug">
el-card
  el-space
    el-form
      el-form-item
        el-date-picker(
          v-model="dateRange"
          @change="handleDateRangeChange"
          type="daterange"
          value-format="x"
          range-separator="—"
          format="DD.MM.YYYY"
        )
el-tabs
  el-tab-pane(label="По исполнителям" lazy)
    by-performer(:since="params.since" :to="params.to")
  el-tab-pane(label="По типам" lazy)
    by-type(:since="params.since" :to="params.to")
</template>

<script setup lang="ts">
import { ref } from 'vue'
import ByPerformer from '@/components/ReportsV2/ByPerformer.vue'
import ByType from '@/components/ReportsV2/ByType.vue'

const DAY_DELIM_1 = 10
const DAY_DELIM_2 = 25

const getCurrentPeriod = (): { since: number, to: number } => {
    const today = new Date()

    const _year = today.getFullYear()
    const _month = today.getMonth()
    const _day = today.getDate()

    const periodStart = _day < DAY_DELIM_1
    ? new Date(_year, _month - 1, DAY_DELIM_2).getTime()
    : _day < DAY_DELIM_2
    ? new Date(_year, _month, DAY_DELIM_1).getTime()
    : new Date(_year, _month, DAY_DELIM_2).getTime()

    const periodEnd = _day < DAY_DELIM_1
    ? new Date(_year, _month, DAY_DELIM_1).getTime() - 1
    : _day < DAY_DELIM_2
    ? new Date(_year, _month, DAY_DELIM_2).getTime() - 1
    : new Date(_year, _month + 1, DAY_DELIM_1).getTime() - 1

    return {
        since: ~~(periodStart / 1e3),
        to: ~~(periodEnd / 1e3),
    }
}

const params = ref<{ since: number, to: number }>(getCurrentPeriod())
const dateRange = ref<number[]>(
  [params.value.since * 1e3, params.value.to * 1e3]
)

const handleDateRangeChange = (range: number[]) => {
    const start = range[0]
    const end = range[1]

    params.value.since = ~~(start / 1e3)
    params.value.to = ~~((end + 24 * 60 * 60 * 1000) / 1e3) - 1
}
</script>
