import { defineStore } from 'pinia'

import type {
    Task,
    TaskEntry,
    TaskType,
    TasksListRequest,
    CreateTaskRequest,
    EntriesListRequest,
    ActionKey,
    Action
} from '@/models/tasks_v2'
import { User, ListResponse } from '@/models/common'
import { File as _File } from '@/models'

import axios from '@/utils/axios'


const UPLOAD_TIMEOUT = 1000 * 60 * 30

interface WithActions extends Object {
    actions?: Action[]
}

type ProgressCallback = (percent: number) => void

interface State {
    types: TaskType[]
    tasks: Task[]
    entries: TaskEntry[]
    users: User[]
    total: number
}

export const useTasksV2Store = defineStore('tasks_v2', {
    state: (): State => ({
        types: [],
        tasks: [],
        entries: [],
        users: [],
        total: 0,
    }),

    actions: {
        async getTaskTypes() {
            const { data } = await axios.get<ListResponse<TaskType>>('/v2/types')

            this.types = data.results
        },


        async getTasks(params: TasksListRequest) {
            if (params.since) {
                params.since /= 1e3
            }
            if (params.to) {
                params.to /= 1e3
            }

            const { data } = await axios.get<ListResponse<Task>>('/v2/tasks', { params })

            this.tasks = data.results
            this.total = data.total ?? 0
        },


        async getTaskByUid(uid: string): Promise<Task> {
            const { data } = await axios.get<Task>(`/v2/tasks/${uid}`)

            this.replaceTaskWithUpdated(data)

            return data
        },


        async getTaskEntries(uid: String): Promise<TaskEntry[]> {
            const { data } = await axios.get<ListResponse<TaskEntry>>(`/v2/tasks/${uid}/entries`)

            this.entries = data.results

            return data.results
        },


        async getEntries(params: EntriesListRequest) {
            const { data } = await axios.get<ListResponse<TaskEntry>>('/v2/entries', { params })
            this.entries = data.results
            this.total = data.total ?? 0
        },


        async getTaskEntry() {},


        async createTask(params: CreateTaskRequest & {progressCallback: (percent: number) => void}) {
            const fd = new FormData()

            if (params.performers?.length) {
                params.performers.forEach(username => {
                    fd.append('performers', username)
                })
            }

            if (params.priority) {
                fd.append('priority', String(params.priority))
            }

            if (params.description?.length) {
                fd.append('description', params.description)
            }

            fd.append('type', params.type)
            fd.append('file', params.file)

            await axios.post(
                '/v2/tasks/',
                fd,
                {
                    timeout: UPLOAD_TIMEOUT,
                    onUploadProgress: (event) =>
                        params.progressCallback(Math.round((event.progress || 0) * 100))
                }
            )
        },


        replaceTaskFile(
            taskUID: string,
            file: File,
            progressCallback: ProgressCallback
        ): {promise: Promise<void>, cancel: () => void} {
            const abortController = new AbortController()
            const fd = new FormData()

            fd.append('file', file)

            const promise = axios.post(
                `/v2/tasks/${taskUID}`,
                fd,
                {
                    timeout: UPLOAD_TIMEOUT,
                    signal: abortController.signal,
                    onUploadProgress: (event) =>
                        progressCallback(Math.round((event.progress || 0) * 100))
                }
            )
                .then(() => {})

            return {promise, cancel: () => abortController.abort()}
        },


        uploadTaskEntryResult(
            taskUID: string,
            entryUID: string,
            file: File,
            progressCallback: (percent: number) => void
        ): {promise: Promise<void>, cancel: () => void} {
            const abortController = new AbortController()
            const fd = new FormData()

            fd.append('result', file)

            const promise = axios.post(
                `/v2/tasks/${taskUID}/entries/${entryUID}`,
                fd,
                {
                    timeout: UPLOAD_TIMEOUT,
                    signal: abortController.signal,
                    onUploadProgress: (event) =>
                        progressCallback(Math.round((event.progress || 0) * 100))
                }
            )
                .then(() => {})

            return {promise, cancel: () => abortController.abort()}
        },


        async setPerformerComment(taskUID: string, entryUID: string, comment: string) {
            const fd = new FormData()
            fd.append('performerComment', comment)

            const { data } = await axios.post<TaskEntry>(`v2/tasks/${taskUID}/entries/${entryUID}`, fd)
            this.replaceEntryWithUpdated(data)
        },


        async setValidatorComment(taskUID: string, entryUID: string, comment: string) {
            const fd = new FormData()
            fd.append('validatorComment', comment)

            const { data } = await axios.post<TaskEntry>(`v2/tasks/${taskUID}/entries/${entryUID}`, fd)
            this.replaceEntryWithUpdated(data)
        },


        async setTaskEntryDefect(taskUID: string, entryUID: string, defect: boolean) {
            const fd = new FormData()
            fd.append('defect', String(defect))

            const { data } = await axios.post<TaskEntry>(`v2/tasks/${taskUID}/entries/${entryUID}`, fd)
            this.replaceEntryWithUpdated(data)
        },


        async confirmTaskEntry(taskUID: string, entryUID: string) {
            const fd = new FormData()
            fd.append('confirm', String(true))

            const { data } = await axios.post(`v2/tasks/${taskUID}/entries/${entryUID}`, fd)
            this.replaceEntryWithUpdated(data)
        },


        async declineTaskEntry(taskUID: string, entryUID: string) {
            const fd = new FormData()
            fd.append('decline', String(true))

            const { data } = await axios.post(`v2/tasks/${taskUID}/entries/${entryUID}`, fd)
            this.replaceEntryWithUpdated(data)
        },


        async getUsers() {
            const { data } = await axios.get<ListResponse<User>>('tasks/users');

            this.users = data.results
        },


        async setTaskPerformer(uid: string) {
            const fd = new FormData()

            fd.append('perform', String(true))

            const { data } = await axios.post<Task>(`v2/tasks/${uid}`, fd)
            this.replaceTaskWithUpdated(data)
        },


        async setTaskEntryPaid(taskUID: string, entryUID: string) {
            const fd = new FormData()
            fd.append('paid', String(true))
            await axios.post<TaskEntry>(`/v2/tasks/${taskUID}/entries/${entryUID}`, fd)
        },


        async setTaskEntryValidationPaid(taskUID: string, entryUID: string) {
            const fd = new FormData()
            fd.append('validationPaid', String(true))
            await axios.post<TaskEntry>(`/v2/tasks/${taskUID}/entries/${entryUID}`, fd)
        },


        replaceTaskWithUpdated(updated: Task) {
            const id = this.tasks.findIndex((task: Task) => task.uid === updated.uid)
            this.tasks[id] = updated
        },


        replaceEntryWithUpdated(updated: TaskEntry) {
            const id = this.entries.findIndex((entry: TaskEntry) => entry.uid === updated.uid)
            this.entries[id] = updated
        },


        isActionAvailable(obj: WithActions | undefined, actionKey: ActionKey): boolean {
            if (!obj?.actions?.length) {
                return false
            }

            return Boolean(obj.actions.find((action: Action) => action.key == actionKey))
        }
    },
})
