<template lang="pug">
el-card
    el-form(:inline="true")
        el-form-item
            el-date-picker(
                v-model="dateRange"
                @change="handleDateRangeChange"
                type="daterange"
                value-format="x"
                range-separator="—"
                format="DD.MM.YYYY"
            )
el-tabs(type="card")
    el-tab-pane(label="По исполнителям" lazy)
        PerformersReport(:params="params")
    el-tab-pane(label="По типам" lazy)
        TypesReport(:params="params")
</template>

<script setup lang="ts">
import { ref } from 'vue';

import type { ReportsListQueryParams, Period } from '@/models';
import PerformersReport from '@/components/PerformersReport.vue';
import TypesReport from '@/components/TypesReport.vue';

const DAY_DELIM_1 = 10;
const DAY_DELIM_2 = 25;

const getPeriod = (): Period => {
    const today = new Date()

    const _year = today.getFullYear()
    const _month = today.getMonth()
    const _day = today.getDate()

    const periodStart = _day < DAY_DELIM_1
    ? new Date(_year, _month - 1, DAY_DELIM_2).getTime()
    : _day < DAY_DELIM_2
    ? new Date(_year, _month, DAY_DELIM_1).getTime()
    : new Date(_year, _month, DAY_DELIM_2).getTime()

    const periodEnd = _day < DAY_DELIM_1
    ? new Date(_year, _month, DAY_DELIM_1).getTime() - 1
    : _day < DAY_DELIM_2
    ? new Date(_year, _month, DAY_DELIM_2).getTime() - 1
    : new Date(_year, _month + 1, DAY_DELIM_1).getTime() - 1

    return {
        periodStart: ~~(periodStart / 1e3),
        periodEnd: ~~(periodEnd / 1e3),
    }
}

const params = ref<ReportsListQueryParams>({
    page: 1,
    perPage: 50,
    ...getPeriod(),
});

const dateRange = ref<Array<number>>([params.value.periodStart * 1e3, params.value.periodEnd * 1e3])

const handleDateRangeChange = (range: Array<number>) => {
    const start = range[0]
    const end = range[1]

    params.value.periodStart = ~~(start / 1e3)
    params.value.periodEnd = ~~((end + 24 * 60 * 60 * 1000) / 1e3) - 1
}
</script>
