import { defineStore } from 'pinia';
import axios from '@/utils/axios';
import type {
  Requisites,
  SelfEmploymentCert,
  Contract,
  Act,
} from '@/models/documents';

interface State {
  requisites?: Requisites;
  selfEmploymentCert?: SelfEmploymentCert;
  contract?: Contract;
  acts: Act[];
};

export const useDocumentsStore = defineStore('documents', {
  state: (): State => ({
    requisites: undefined,
    selfEmploymentCert: undefined,
    contract: undefined,
    acts: [],
  }),

  actions: {
    async getRequisites() {
      const { data } = await axios.get<{ requisites: Requisites }>('documents/requisites');
      this.$patch(data);
    },

    async getDocuments() {
      await this.getCertificate();
      await this.getContract();
    },

    async getCertificate() {
      const { data } = await axios.get<{ selfEmploymentCert: SelfEmploymentCert }>('documents/certificate');
      this.$patch(data);
    },

    async getContract() {
      const { data } = await axios.get<{ contract: Contract }>('documents/contract');
      this.$patch(data);
    },

    async getActs() {
      const { data } = await axios.get<{ acts: Act[] }>('documents/acts');
      this.$patch(data);
    },

    async createAct(
      amount: string,
      serviceName: string,
      periodStart: number,
      periodEnd: number,
    ) {
      const fd = new FormData();

      fd.append('action', 'create');
      fd.append('periodStart', String(periodStart));
      fd.append('periodEnd', String(periodEnd));
      fd.append('amount', amount);
      fd.append('serviceName', serviceName);

      await axios.post<{ act: Act }>('documents/acts', fd);
    },

    async updateRequisites(requisites: Requisites) {
      const fd = new FormData();

      Object.entries(requisites).forEach(([k, v]) => {
        fd.append(k, v);
      });

      const { data } = await axios.post<{
        requisites: Requisites,
      }>('documents/requisites', fd);

      this.$patch(data);
    },

    async updateContractName(contractName: string) {
      const fd = new FormData();
      fd.append('contractName', contractName);

      const { data } = await axios.post<{ contract: Contract }>('documents/contract', fd);
      this.$patch(data);
    },

    async uploadAct(file: File) {
      await this._uploadFile('act', 'documents/acts', file);
    },

    async updateAct(
      act: Act,
      amount: string,
      serviceName: string,
    ) {
      const fd = new FormData();

      fd.append('action', 'update');
      fd.append('amount', amount);
      fd.append('serviceName', serviceName);

      await axios.post<{ act: Act }>(`documents/acts/${act.uuid}`, fd);
    },

    async updateActReceipt(file: File, uuid: String) {
      await this._uploadFile('receipt', `documents/acts/${uuid}`, file);
    },

    async uploadSelfEmploymentCert(file: File) {
      await this._uploadFile('selfEmploymentCert', 'documents/certificate', file);
    },

    async uploadContract(file: File) {
      await this._uploadFile('contract', 'documents/contract', file);
    },

    async _uploadFile(key: string, path: string, file: File) {
      const fd = new FormData();
      fd.append(key, file);
      await axios.post(path, fd);
    },
  }
});
