<template lang="pug">
el-descriptions(direction="horizontal", :column="1" :border="true")
  el-descriptions-item(label="Валидатор") {{ props.entry.validatedBy || '—' }}
  el-descriptions-item(label="Провалидировано")
    el-text(v-if="props.entry.validatedAt") {{ new Date(props.entry.validatedAt * 1e3).toLocaleString() }}
    el-text(v-else) —
  el-descriptions-item(label="Комментарий исполнителя")
    el-input(
      v-if="tasksStore.isActionAvailable(props.entry, 'CommentAsPerformer')"
      v-model="performerComment"
      type="textarea"
      autosize
      resize="none"
    ) {{ props.entry.performerComment }}
    el-text(v-else) {{ props.entry.performerComment || '—' }}
    el-row(justify="end" style="margin-top: 1em;")
      el-button(v-if="performerCommentChanged" round plain @click="setPerformerComment") Отправить
  el-descriptions-item(label="Комментарий валидатора")
    el-input(
      v-if="tasksStore.isActionAvailable(props.entry, 'CommentAsValidator')"
      v-model="validatorComment"
      type="textarea"
      autosize
      resize="none"
    ) {{ props.entry.validatorComment }}
    el-text(v-else) {{ props.entry.validatorComment || '—' }}
    el-row(justify="end" style="margin-top: 1em;")
      el-button(v-if="validatorCommentChanged" round plain @click="setValidatorComment") Отправить
</template>

<script setup lang="ts">
import { useTasksV2Store } from '@/store/tasks_v2'
import type { TaskEntry } from '@/models/tasks_v2'

const props = defineProps<{
  entry: TaskEntry,
}>()

const tasksStore = useTasksV2Store()

const performerComment = ref('')
const performerCommentChanged = computed(() => props.entry.performerComment !== performerComment.value)

const validatorComment = ref('')
const validatorCommentChanged = computed(() => props.entry.validatorComment !== validatorComment.value)

const setPerformerComment = () => {
  tasksStore.setPerformerComment(props.entry.taskUid, props.entry.uid, performerComment.value)
}

const setValidatorComment = () => {
  tasksStore.setValidatorComment(props.entry.taskUid, props.entry.uid, validatorComment.value)
}

onMounted(() => {
  performerComment.value = props.entry.performerComment
  validatorComment.value = props.entry.validatorComment
})
</script>
