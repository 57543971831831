<template lang="pug">
el-card
  stat-options(
    :metric="metric"
    :option="option"
    :period="period"
    @metric-changed="setMetric"
    @option-changed="setOption"
    @period-changed="setPeriod"
  )
el-card#chart_container
  canvas#chart
</template>

<script setup lang="ts">
import Chart from 'chart.js/auto'
import type { ChartDataset } from 'chart.js'
import type { Option, Period, Metric } from '@/models/stat'
import { useStatStore } from '@/store/stat'
import StatOptions from '@/components/Stat/StatOptions.vue'

type Dataset = ChartDataset<'bar'>

const option = ref<Option>('by-types')
const period = ref<Period>('week')
const metric = ref<Metric>('count')
const statStore = useStatStore()

let chart: Chart | undefined

const renderChart = () => {
  const data = statStore.data;

  if (chart) {
    chart.destroy();
  }

  const indexes = [
    ...(new Set(data.map((i) => i.label))),
  ];

  const measurements = new Set<string>();

  data.forEach((row) => {
    row.data?.forEach((rowData) => {
      measurements.add(rowData.label);
    });
  });

  const datasets: Dataset[] = [];

  measurements.forEach((measurement) => {
    const dataset: Dataset = {
      label: measurement,
      data: Array(indexes.length).fill(null),
    };

    indexes.forEach((index, id) => {
      const count = data
        .find((row) => row.label === index)?.data
        .find((row) => row.label === measurement)?.count
        ?? null;

      dataset.data[id] = count;
    });

    datasets.push(dataset);
  });

  let xAxisLabel = ''
  switch (metric.value) {
    case 'count':
      xAxisLabel = 'Количество разметок'
      break
    case 'declined-percent':
      xAxisLabel = 'Процент отклоненных'
      break
    case 'supplemented-percent':
      xAxisLabel = 'Процент доразметки'
      break
  }

  const scales = {
    x: {
      grid: { display: false },
      title: { display: true, text: xAxisLabel, align: 'end' },
    },
    y: {
      ticks: { minRotation: 45 },
    },
  }

  chart = new Chart(
    document.getElementById('chart') as HTMLCanvasElement,
    {
      type: 'bar',
      data: { labels: indexes, datasets },
      options: {
        // @ts-ignore
        skipNull: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales,
        datasets: { bar: { borderRadius: 6 } },
        plugins: {
          legend: {
            position: 'right',
            align: 'start',
            labels: { usePointStyle: true, font: { size: 10 } },
          },
        },
      },
      plugins: [{
        id: 'afterDraw',
        afterDraw: (chartInstance) => {
          if (!chartInstance.data.datasets.length) {
            const ctx = chartInstance.ctx;
            const [w, h] = [chartInstance.width, chartInstance.height];

            ctx.textAlign = 'center';
            ctx.fillText('Нет данных', w / 2, h / 2);
          }
        },
      }],
    },
  )
}

const updateChart = () => {
  statStore.getStat(option.value, period.value, metric.value)
    .then(renderChart)
}

const setOption = (o: Option) => {
  option.value = o
  updateChart()
}

const setPeriod = (p: Period) => {
  period.value = p
  updateChart()
}

const setMetric = (m: Metric) => {
  metric.value = m
  updateChart()
}

onMounted(() => {
  updateChart()
})
</script>

<style scoped lang="scss">
#chart {
  width: 100%;
}
</style>
