<template lang="pug">
el-form
  el-form-item(label="Месяц")
    el-date-picker(
      v-model="monthTimestamp"
      type="month"
      value-format="x"
      format="MMM YYYY"
      :clearable="false"
    )
  el-form-item(label="Сумма")
    el-input(v-model="amount" :formatter="amountFormatter")
  el-form-item(label="Наименование услуги")
    el-input(v-model="serviceName")
  el-form-item
    el-button(
      plain @click="create"
    ) Создать
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useDocumentsStore } from '@/store/documents';

const documentsStore = useDocumentsStore();

const monthTimestamp = ref<number>(new Date().getTime());
const amount = ref('');
const serviceName = ref('');

const emit = defineEmits(['created']);

const create = () => {
  const [periodStartDate, periodEndDate] = getMonthDateRange(monthTimestamp.value);

  documentsStore.createAct(
    amount.value,
    serviceName.value,
    periodStartDate.getTime() / 1e3,
    periodEndDate.getTime() / 1e3,
  )
    .then(() => emit('created'));
}

const getMonthDateRange = (monthTimestamp: number): [Date, Date] => {
  const d = new Date();
  d.setTime(monthTimestamp);

  const [year, month] = [d.getFullYear(), d.getMonth()];

  const periodStartDate = new Date(year, month, 1);
  const periodEndDate = new Date(year, month + 1, 0);

  return [periodStartDate, periodEndDate];
}

const amountFormatter = (v: string) => {
  v = v.replaceAll(/^(\.)|([^\d\.])/g, '');
  v = v.replaceAll(/\.+/g, '.');

  const [i, d, ..._] = v.split('.');

  if (d) {
    v = [i, d].join('.');
  }

  if (d?.length > 2) {
    v = [i, d.substring(0, 2)].join('.');
  }

  return v;
}
</script>
