import { defineStore } from 'pinia';

import axios from '../utils/axios';
import {
    ReportInfo,
    ReportsListQueryParams,
    ListReports,
    ReportDetailInfo,
    TypesReportInfo,
    TypesReportParams,
} from '../models';


interface State {
    total: number;
    periodStart?: number;
    periodEnd?: number;
    reports: ReportInfo[],
    reportsDetails: {[key in number]: ReportDetailInfo},
    typesReport: TypesReportInfo[],
}

export const useReportsStore = defineStore('reports', {
    state: (): State => ({
        total: 0,
        periodStart: undefined,
        periodEnd: undefined,
        reports: [],
        reportsDetails: {},
        typesReport: [],
    }),

    actions: {
        async list(params: ReportsListQueryParams) {
            const {
                data: {
                    total,
                    periodStart,
                    periodEnd,
                    results,
                }
            } = await axios.get<ListReports<ReportInfo>>('tasks/reports', { params });
            this.total = total;
            this.periodStart = periodStart;
            this.periodEnd = periodEnd;
            this.reports = results;
        },

        async getReportTasks(userId: number, params: ReportsListQueryParams) {
            const {
                data: {
                    performedTasks,
                    validatedTasks,
                }
            } = await axios.get<ReportDetailInfo>(`tasks/reports/details/${userId}`, { params })

            this.reportsDetails[userId] = {
                'performedTasks': performedTasks,
                'validatedTasks': validatedTasks,
            }
        },

        async getTypesReport(params: TypesReportParams) {
            const {
                data: {
                    periodStart,
                    periodEnd,
                    results,
                }
            } = await axios.get<ListReports<TypesReportInfo>>('tasks/reports/by-type', { params })

            this.periodStart = periodStart
            this.periodEnd = periodEnd
            this.typesReport = results
        },

        getExcelReportLink(params: ReportsListQueryParams) {
            return `${axios.defaults.baseURL}/tasks/reports?periodStart=${params.periodStart}&periodEnd=${params.periodEnd}&excel`
        },
    }
})
