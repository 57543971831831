<template lang="pug">
el-tooltip(
  v-if="tasksStore.isActionAvailable(props.entry, Actions.DEFECT)"
  content="Пометить как брак"
)
  el-button(circle @click="setDefect(true)") 🗑️
el-tooltip(
  v-if="tasksStore.isActionAvailable(props.entry, Actions.NOT_DEFECT)"
  content="Снять отметку брака"
)
  el-button(circle @click="setDefect(false)") ♻️
</template>

<script setup lang="ts">
import { useTasksV2Store } from '@/store/tasks_v2'
import type { TaskEntry } from '@/models/tasks_v2'
import { Actions } from '@/utils/actions'

const props = defineProps<{
  entry: TaskEntry
}>()

const tasksStore = useTasksV2Store()

const setDefect = (defect: boolean) => {
  tasksStore.setTaskEntryDefect(props.entry.taskUid, props.entry.uid, defect)
}
</script>
