<template lang="pug">
el-card
    el-form(:inline="true")
        el-form-item(style="width: 15%")
            el-select(placeholder="Тип" v-model="listParams.type" clearable @change="listTasks")
                el-option(
                    placeholder="Выберите тип"
                    v-for="item in tasksStore.typesList"
                    :label="item.name"
                    :value="item.key"
                )
        el-form-item(style="width: 15%")
            el-select(placeholder="Статус" v-model="listParams.status" clearable @change="listTasks")
                el-option(
                    v-for="item in statuses"
                    :label="item.label"
                    :value="item.value"
                )
        el-form-item(style="width: 35%")
            el-date-picker(
                type="daterange"
                v-model="dateRange"
                format="DD.MM.YYYY"
                value-format="x"
                range-separator="—"
                clearable
                @change="applyDateRange"
            )
        el-form-item
            el-button(
                plain
                v-if="selectedRows && selectedRows.length"
                @click="(linksDialogVisible = true) && getLinks()"
            ) Показать ссылки

el-card
    el-table(
        ref="tasksTable"
        :data="tasksStore.tasks"
        v-loading="loading"
        style="width:100%;"
        @select-all="handleSelectAll"
        @row-click="handleRowClick"
    )
        el-table-column(type="selection")
        el-table-column(
            prop="type"
            label="Тип"
            v-slot="scope"
            header-align="center"
            align="center"
        ) {{ scope.row.type.name }}
        el-table-column(
            prop="status"
            label="Статус"
            v-slot="scope"
            header-align="center"
            align="center"
        )
            el-text(
                type="danger"
                v-if="scope.row.status == STATUS_DECLINED"
            ) {{ taskStatuses[scope.row.status] }}
            el-text(
                type="success"
                v-else-if="scope.row.status == STATUS_CONFIRMED"
            ) {{ taskStatuses[scope.row.status] }}
            el-text(
                type="info"
                v-else-if="scope.row.status == STATUS_AWAITING_CONFIRMATION"
            ) {{ taskStatuses[scope.row.status] }}
            el-text(
                v-else
            ) {{ taskStatuses[scope.row.status] }}
        el-table-column(
                prop="performer"
                label="Исполнитель"
                v-slot="scope"
                header-align="center"
                align="center"
            )
        el-table-column(prop="createdBy" label="Создан" width="180" header-align="center" align="center")

    el-pagination.center(
        layout="total, prev, pager, next, sizes"
        :total="tasksStore.total || 0"
        v-model:current-page="listParams.page"
        v-model:page-size="listParams.perPage"
        :page-sizes="[10, 30, 50, 100]"
        @current-change="listTasks"
        @size-change="listTasks"
    )

el-dialog(
    v-model="linksDialogVisible"
    title="Ссылки"
    width="40%"
    align-center=true
    @close="linksDialogVisible = false"
)
    ul
        li(v-for="link in links" style="list-style-type:none;")
            el-link(:href="link") {{ link }}
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { ElMessage, ElTable } from 'element-plus'

import { useTasksStore } from '../store/tasks'
import type { ListParams, TaskInfo } from '../models'


const STATUS_AWAITING_CONFIRMATION = 20
const STATUS_DECLINED = 30
const STATUS_CONFIRMED = 40

const taskStatuses = {
  20: 'Ожидает подтверждения',
  30: 'Отклонено',
  40: 'Подтверждено',
}

const statuses = [
  { value: STATUS_AWAITING_CONFIRMATION, label: 'Ожидает подтверждения' },
  { value: STATUS_DECLINED, label: 'Отклонено' },
  { value: STATUS_CONFIRMED, label: 'Подтверждено' },
]


const listParams = ref<ListParams>({
  page: 1,
  perPage: 100,
  orderBy: '-id',
  status: STATUS_CONFIRMED,
})
const dateRange = ref<number[]>([])
const tasksTable = ref<InstanceType<typeof ElTable>>()
const selectedRows = ref<TaskInfo[] | undefined>([])
const loading = ref(false)
const linksDialogVisible = ref(false)
const links = ref<string[]>([])
const tasksStore = useTasksStore()


const applyDateRange = () => {
    listParams.value.startDate = dateRange.value[0]
    listParams.value.endDate = dateRange.value[1]

    listTasks()
}

const getLinks = () => {
    links.value = []
    selectedRows.value?.forEach(async (row: TaskInfo) => {
        const file = await tasksStore.finalFile(row.uid)
        links.value.push(file.url)
    })
}

const handleSelectAll = (rows: TaskInfo[]) => {
    selectedRows.value = tasksTable.value?.getSelectionRows()
}

const handleRowClick = (row: TaskInfo, _: any, __: any) => {
    tasksTable.value!.toggleRowSelection(row, undefined!)

    selectedRows.value = tasksTable.value?.getSelectionRows()
}

const restoreSelection = () => {
    selectedRows.value?.forEach(row => {
        tasksTable.value?.toggleRowSelection(row, true)
    })
}

const listTypes = () => {
    tasksStore.types()
        .catch(() => ElMessage.error('Ошибка при получении списка типов'))
}

const listTasks = () => {
    loading.value = true
    tasksStore.list(listParams.value)
        .then(() => restoreSelection())
        .catch((e) => ElMessage.error('Ошибка при получении списка задач'))
        .finally(() => loading.value = false)
}

listTypes()
listTasks()
</script>
