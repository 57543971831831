<template lang="pug">
el-menu.vh-100(
  :default-active="activeRouteIndex"
  :collapse="isCollapsed"
  :collapse-transition="false"
)
  el-button#collapse-btn(
    @click="isCollapsed = !isCollapsed"
    :icon="isCollapsed ? DArrowRight : DArrowLeft"
    plain
  )
  router-link(v-for="(item, index) in menu" :to="item.address" tag="div" :key="item.address")
    el-menu-item(:index="`${index}`" :key="index" v-if="checkIfAllowed(item)")
      component(:is="item.icon" style="height: 2em")
      el-button(style="margin-left: 1em;" link v-if="isCollapsed === false") {{ item.name }}
  el-card(v-if="!isCollapsed" class="auth-state")
    div.auth-state-body
      span {{ authStore.fullName }}
      el-button(@click="router.push('/profile')" :icon="User" plain) Профиль
      el-button.logout(@click="authStore.logout()" type="danger" plain) Выйти
      el-text.copyright © AI Diagnostic {{new Date().getFullYear()}}
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import {
  List,
  Document,
  Link,
  User,
  DArrowLeft,
  DArrowRight,
  Histogram,
  Notification,
} from '@element-plus/icons-vue';
import router from '@/router';
import { useAuthStore } from '@/store/auth';

const isCollapsed = ref(false);
const loading = ref(true);
const authStore = useAuthStore();

const menu = [
  {
    name: 'Список задач',
    address: '/',
    icon: List,
    requiresAdmin: false,
  },
  {
    name: 'Отчеты',
    address: '/reports',
    icon: Document,
    requiresAdmin: true,
  },
  {
    name: 'Выгрузка результатов',
    address: '/tasks-results',
    icon: Link,
    requiresAdmin: true,
  },
  {
    name: 'Задачи (Slicer)',
    address: '/v2',
    icon: List,
    requiresAdmin: false,
  },
  {
    name: 'Выгрузка результатов (Slicer)',
    address: '/v2/annotation-results',
    icon: Link,
    requiresAdmin: true,
  },
  {
    name: 'Отчеты (Slicer)',
    address: '/v2/reports',
    icon: Document,
    requiresAdmin: true,
  },
  {
    name: 'Статистика',
    address: '/v2/stat',
    icon: Histogram,
    requiresAdmin: false,
  },
  {
    name: 'Уведомления',
    address: '/notifications',
    icon: Notification,
    requiresAdmin: false,
  },
];

const checkIfAllowed = (menuItem: { requiresAdmin: Boolean }) => {
  return menuItem.requiresAdmin ? menuItem.requiresAdmin === authStore.isAdmin : true
}

const activeRouteIndex = computed(() => {
  return menu.findIndex(item =>
      item.address === useRoute().path
  ).toString();
});

authStore.getCurrentUser().finally(() => loading.value = false);
</script>

<style lang="scss">
.auth-state {
  position: absolute;
  bottom: 0;
  width: 88%;
  padding-left: calc(var(--el-menu-base-level-padding) + var(--el-menu-level) * var(--el-menu-level-padding));

  .auth-state-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .logout {
    margin-left: 0 !important;
  }

  .copyright {
    align-self: flex-start;
  }
}

#collapse-btn {
  position: absolute;
  right: 0;
  top: 50%;
  width: 1%;
  transform: translate(50%, -50%);
}
</style>
