import { defineStore } from 'pinia';

import axios from '@/utils/axios';

interface State {
    loggedIn: boolean;
    firstName: string;
    lastName: string;
    patronymic: string;
    isAdmin: boolean;
    isValidator: boolean;
    groups: string[];
}

interface UserData {
    firstName: string;
    lastName: string;
    patronymic: string;
    isAdmin: boolean;
    isValidator: boolean;
    groups: string[];
}

export const useAuthStore = defineStore('auth', {
    state: (): State => ({
        loggedIn: false,
        firstName: '',
        lastName: '',
        patronymic: '',
        isAdmin: false,
        isValidator: false,
        groups: []
    }),

    getters: {
        fullName: (state) => `${state.firstName} ${state.lastName}`
    },

    actions: {
        async login(username: string, password: string) {
            const { data } = await axios.post<UserData>('login', { username, password });

            this.updateUser(data);
        },

        async logout() {
            await axios.post('logout');
            this.clearUser();
        },

        async getCurrentUser() {
            try {
                const { data } = await axios.get<UserData>('me');

                this.updateUser(data);
            } catch (e) {}
        },

        updateUser(data: UserData) {
            this.loggedIn = true;
            this.$patch(data);
        },

        clearUser() {
            this.$reset();
        }
    }
});
