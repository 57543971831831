<template lang="pug">
task-details(
  v-if="showDialogs.task"
  v-model:dialogVisible="showDialogs.task"
  @updated="update"
  @updatedSelectedTask="updateSelectedTask"
  :task="task"
)

task-create(
  v-if="showDialogs.create"
  v-model:dialogVisible="showDialogs.create"
  @updated="update"
  :task="task"
)

el-card
  el-form(:inline="true")
    el-form-item(style="width: 15%")
      el-select(
        placeholder="Тип"
        v-model="params.type"
        @change="update"
        clearable
      )
        el-option(
          placeholder="Выберите тип"
          v-for="item in tasksStore.typesList"
          :label="item.name"
          :value="item.key"
        )

    el-form-item(style="width: 15%")
      el-select(
        placeholder="Статус"
        v-model="params.status"
        @change="update"
        clearable
      )
        el-option(
          v-for="item in statuses"
          :label="item.label"
          :value="item.value"
        )
    el-form-item
      el-date-picker(
        type="daterange"
        v-model="dateRange"
        @change="applyDateRange"
        format="LL"
        value-format="x"
        clearable
      )

    el-form-item: el-button(:icon="Open" @click="createTaskForm" v-if="!!authStore.isAdmin") Создать

el-card
  el-table(
    :data="tasksStore.tasks"
    style="width: 100%"
    v-loading="loading"
    @sort-change="sortColumn"
    table-layout="auto"
  )
    el-table-column(
      prop="type"
      label="Тип"
      width="180"
      header-align="center"
      v-slot="scope"
    ) {{ scope.row.type.name }}
    el-table-column(v-slot="scope" align="center")
      el-button(
        v-if="!scope.row.performer && !authStore.isAdmin"
        @click="setPerformer(scope.row)"
        :icon="Select"
      ) Выполнить
      el-button(
        v-if="!!scope.row.performer"
        @click="openTask(scope.row)"
        :icon="Open"
      ) Открыть
    el-table-column(label="Описание" v-slot="scope" align="center" header-align="center")
      pre {{ scope.row.description || '--' }}
    el-table-column(
      prop="priority"
      label="Приоритет"
      width="140"
      v-slot="scope"
      sortable="custom"
      align="center"
      header-align="center"
    ) {{ taskPriorities[scope.row.priority] }}
    el-table-column(
      prop="status"
      label="Статус"
      width="240"
      v-slot="scope"
      sortable="custom"
      align="center"
      header-align="center"
    )
      el-text(
        type="danger"
        v-if="scope.row.status == STATUS_DECLINED"
      ) {{ taskStatuses[scope.row.status] }}
      el-text(
        type="success"
        v-else-if="scope.row.status == STATUS_CONFIRMED"
      ) {{ taskStatuses[scope.row.status] }}
      el-text(
        type="info"
        v-else-if="scope.row.status == STATUS_AWAITING_CONFIRMATION"
      ) {{ taskStatuses[scope.row.status] }}
      el-text(
        v-else
      ) {{ taskStatuses[scope.row.status] }}
    el-table-column(
      prop="performer"
      label="Исполнитель"
      width="180"
      align="center"
      header-align="center"
      v-if="authStore.isAdmin"
    )
    el-table-column(
      prop="createdBy"
      label="Создан"
      width="180"
      align="center"
      header-align="center"
    )

  el-pagination.center(
    layout="total, prev, pager, next, sizes"
    :total="tasksStore.total || 0"
    v-model:current-page="params.page"
    v-model:page-size="params.perPage"
    :page-sizes="[10, 30, 50, 100]"
    @current-change="update"
    @size-change="update"
  )
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import { Select, Open } from '@element-plus/icons-vue';
import { filesize } from 'filesize';

import type { ListParams, TaskInfo } from '@/models';
import { useTasksStore } from '@/store/tasks';
import { useAuthStore } from '@/store/auth';
import TaskDetails from '@/components/Tasks/Task.vue';
import TaskCreate from '@/components/Tasks/Create.vue';

const tasksStore = useTasksStore();
const authStore = useAuthStore();

const loading = ref(false);

const dateRange = ref<number[]>([]);

const params = ref<ListParams>({
  page: 1,
  perPage: 100,
  orderBy: '-id',
});

const showDialogs = ref({
  task: false,
  create: false
});

const taskPriorities = {
  10: '🔥',
  20: '🔥🔥',
  30: '🔥🔥🔥'
};

const STATUS_AWAITING_PROCESSING = '-30'
const STATUS_UNPACKED = '-20'
const STATUS_UNPACKING_ERROR = '-10'
const STATUS_READY = '0'
const STATUS_IN_PROGRESS = 10
const STATUS_AWAITING_CONFIRMATION = 20
const STATUS_DECLINED = 30
const STATUS_CONFIRMED = 40

const taskStatuses = {
  '-30': 'Ожидает распаковки',
  '-20': 'Распакована',
  '-10': 'Ошибка распаковки',
  0: 'Готова к разметке',
  10: 'В процессе разметки',
  20: 'Ожидает подтверждения',
  30: 'Отклонено',
  40: 'Подтверждено',
};

const statuses = [
  { value: '-30', label: 'Ожидает распаковки' },
  { value: '-20', label: 'Распакована' },
  { value: '-10', label: 'Ошибка распаковки' },
  { value: 0, label: 'Готова к разметке' },
  { value: 10, label: 'В процессе разметки' },
  { value: 20, label: 'Ожидает подтверждения' },
  { value: 30, label: 'Отклонено' },
  { value: 40, label: 'Подтверждено' },
];

const task = ref<TaskInfo>();

const openTask = (target: TaskInfo) => {
  loading.value = true;

  tasksStore.getTask(target.uid)
  .then((t) => task.value = t)
  .then(() => tasksStore.results(target.uid))
  .then(() => showDialogs.value.task = true)
  .catch(() => ElMessage.error('Произошла ошибка при открытии задачи'))
  .finally(() => loading.value = false);
};

const setPerformer = (target: TaskInfo) => {
  loading.value = true;
  task.value = target;
  tasksStore.performer(target.uid)
      .then(update)
      .catch(() => {
        ElMessage.error('Задача недоступна или имеет другого исполнителя.');
        loading.value = false;
      });
};

const createTaskForm = (target: TaskInfo) => {
  task.value = target;
  tasksStore.users(params.value)
  .then(() => showDialogs.value.create = true)
  .finally(() => loading.value = false)
  .catch(() => ElMessage.error('Ошибка при получении списка пользователей'))
};

const sortColumn = ({ prop, order }: { prop: string, order?: string }) => {
  params.value.orderBy = !!order ? (order === 'ascending' ? '' : '-') + prop : undefined;
  update();
};

const applyDateRange = () => {
  [params.value.startDate, params.value.endDate] = dateRange.value || [undefined, undefined];
  update();
};

const update = () => {
  loading.value = true;
  tasksStore.list(params.value)
      .catch(() => ElMessage.error('Ошибка при получении списка задач'))
      .finally(() => loading.value = false);
  tasksStore.types()
      .catch(() => ElMessage.error('Ошибка при получении списка типов'));
};

const updateSelectedTask = () => {
  loading.value = true;
  tasksStore.getTask(task.value!.uid)
  .then((t) => task.value = t)
  .catch(() => ElMessage.error('Произошла ошибка при обновлении задачи'))
  .finally(() => loading.value = false)
}

update();
</script>
