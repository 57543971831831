<template lang="pug">
el-dialog(v-model="dialogVisibleLocal" title="Создание задачи" @close="closeModal" align-center=true)
  el-card(v-loading="loading")
    el-form(
      ref="ruleFormRef"
      :model="form"
      :rules="rules"
    )
      el-form-item(label="Тип" prop="type")
        el-select(
          placeholder="Выберите тип"
          v-model="form.type"
        )
          el-option(
            placeholder="Выберите тип"
            v-for="item in tasksStore.typesList"
            :label="item.name"
            :value="item.key"
          )

      el-form-item(label="Исполнитель" prop="usernames")
        el-select(
          multiple
          filterable
          placeholder="Выберите исполнителя"
          v-model="form.usernames"
        )
          el-option(
            placeholder="Выберите исполнителя"
            v-for="user in tasksStore.usersList"
            :label="user.firstName + ' ' + user.lastName"
            :value="user.username"
          )

      el-form-item(
        label="Приоритет"
        prop="priority"
      )
        el-select(
          placeholder="Выберите приоритет"
          v-model="form.priority"
        )
          el-option(
            v-for="item in priority"
            :label="item.label"
            :value="item.value"
          )

      el-form-item(v-model="form.file" prop="file")
        el-upload(
          drag
          ref="uploadRef"
          :multiple="false"
          :http-request="addFile"
          :on-remove="deleteFile"
          action=""
        )
          i(class="el-icon-upload")
          div(class="el-upload__text")
            span Перетащите файл сюда или
              em  нажмите для загрузки

      el-form-item(label="Разделить файл")
        el-switch(v-model="form.split")

      el-form-item(label="Требуется валидация")
        el-checkbox(v-model="form.validationRequired")

      el-form-item(v-if="form.split" prop="parts" label="Количество снимков в серии.")
        el-input-number(
          v-model="form.parts"
          :min="1"
          :max="100"
        )

      el-form-item(prop="description" label="Описание")
        el-input(v-model="form.description", placeholder="Введите описание" type="textarea")

      el-form-item(label-position="center")
        el-button(@click="createTask" :disabled="!file") Создать

el-progress(
  v-if="uploadingInProgress"
  class="el-progress-upload-file"
  type="circle"
  :style="`z-index: ${elProgressZIndex};`"
  :width="100"
  :percentage="uploadPercent"
  :stroke-width="10"
  :status="uploadStatus"
)
</template>

<style>
.el-progress-upload-file {
  position: fixed !important;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
}
</style>

<script setup lang="ts">

import { useTasksStore } from '../../store/tasks';
import { ref, watch } from 'vue';
import { ElMessage, ElLoading } from 'element-plus';

const tasksStore = useTasksStore();

const emit = defineEmits(['update:dialogVisible', 'updated']);
const closeModal = () => emit('update:dialogVisible', false);

const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const uploadPercent = ref(0)
const uploadStatus = ref('')
const uploadingInProgress = ref(false)
const elProgressZIndex = ref(2010)
const dialogVisibleLocal = ref(props.dialogVisible);

watch(
  () => uploadPercent.value,
  (percent) => percent === 100 ? (uploadStatus.value = 'success') : (uploadStatus.value = '')
)

const rules = {
  type: [{
    required: true,
    trigger: 'change',
    message: 'Выберите тип для задачи.'
  }],
  user: [{
    required: false,
    trigger: 'change',
    message: 'Выберите исполнителя для задачи.'
  }],
  priority: [{
    required: true,
    trigger: 'change',
    message: 'Выберите приоритет для задачи.'
  }],
  parts: [{
    required: true,
    trigger: 'blur',
  }],
  file: [{
    required: true,
    message: 'Выберите файл для загрузки.'
  }],
  description: [{
    required: false,
    message: 'Введите описание.'
  }],
  dueDate: [{
    type: 'date',
    required: true,
    message: 'Срок выполнения.',
    trigger: 'change',
  }]
};


const priority = [
  { value: 10, label: '🔥' },
  { value: 20, label: '🔥🔥' },
  { value: 30, label: '🔥🔥🔥' }
];

const form = ref({
  type: '',
  usernames: [],
  priority: 10,
  split: false,
  validationRequired: true,
  file: File,
  parts: 0,
  description: '',
  dueDate: ''
});

const loading = ref(false);

const file = ref();
const uploadRef: any = ref(null);

const addFile = (f: { file: File }) => {
  uploadRef.value.clearFiles();
  file.value = f.file;
  uploadRef.value.handleStart(f.file);
  return false
};

const deleteFile = () => {
  file.value = undefined;
  uploadRef.value.clearFiles();
};

const createTask = () => {
  const loading = ElLoading.service({
    fullscreen: true,
    lock: true,
    svgViewBox: '0, 0, 0, 0',
  })

  elProgressZIndex.value = Number(loading.$el.style.zIndex) + 1
  uploadingInProgress.value = true

  tasksStore.create(
      form.value.type,
      form.value.usernames,
      form.value.priority,
      file.value,
      form.value.description,
      form.value.split ? form.value.parts : 0,
      form.value.validationRequired,
      (percent) => uploadPercent.value = percent
  )
      .then(() => {
        ElMessage.success('Задание создано.');
        emit('updated');
        closeModal();
      })
      .catch(() => {
        ElMessage.error('Ошибка при создании задания.');
      })
      .finally(() => {
        uploadingInProgress.value = false
        loading.close()
        uploadPercent.value = 0
      })
};
</script>
