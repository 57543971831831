import axios from 'axios';
import { errorResponseInterceptor } from '@/utils/interceptors'

const instance = axios.create({
    baseURL: '/api',
    timeout: 1e4,
});

instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFToken';
instance.defaults.withCredentials = true;
instance.interceptors.response.use(
    response => response,
    error => errorResponseInterceptor(error),
)

export default instance;
