<template lang="pug">
el-card
  el-row(justify="space-between" :gutter="20")
    el-col(:span="12")
      el-descriptions(title="Рекзвизиты" border :column="1")
        el-descriptions-item(v-for="row in requisites" :label="row.label")
          el-row(style="flex-wrap: unset" justify="space-between")
            el-text(
              v-if="!row.edit"
              @click="row.edit = true"
            ) {{ row.value }}
            el-button(
              v-if="!row.edit"
              @click="row.edit = true"
              :icon="EditIcon"
              text
            )
            el-input(
              v-if="row.edit"
              v-model="row.value"
              :placeholder="row.actualValue || row.label"
            )
            el-button(
              v-if="row.edit"
              @click=`() => {
                row.edit = false;
                row.value = row.actualValue;
              }`
              :icon="CloseIcon"
              text
            )
            el-button(
              v-if="row.edit"
              @click="() => row.edit = false"
              :icon="CheckIcon"
              text
            )
      el-row(v-if="isRequisitesChanged" justify="end" style="margin-top: 10px;")
        el-button(@click="updateRequisites") Сохранить

    el-col(:span="12")
      el-descriptions(title="Самозанятость" border :column="1")
        el-descriptions-item(label="Справка о получении статуса самозанятого")
          el-link(
            v-if="documentsStore.selfEmploymentCert"
            type="primary"
            :href="documentsStore.selfEmploymentCert.selfEmploymentCertFile.url"
          ) справка
          el-upload(
            v-else
            ref="uploadRef"
            :show-file-list="false"
            :auto-upload="false"
            @change="handleSelfEmploymentCertUpload"
          )
            el-button Загрузить
      br
      el-descriptions(title="Договор" border :column="1")
        el-descriptions-item(
          v-if="documentsStore.contract"
          :label="`Договор${contractSigningRequired ? ' (требуется подпись)' : ''}`"
        )
          el-row(justify="space-between")
            el-link(
              type="primary"
              :href="documentsStore.contract.contractFile.url"
            ) договор
            el-upload(
              v-if="contractSigningRequired"
              ref="uploadRef"
              :show-file-list="false"
              :auto-upload="false"
              @change="handleContractUpload"
            )
              el-button Загрузить
        el-descriptions-item(
          v-if="documentsStore.contract"
          label="Название договора"
        )
          el-row(justify="space-between")
            el-text {{ documentsStore.contract?.name }}
            el-button(plain @click="changeContractNameVisible = true") Изменить

el-dialog(v-model="changeContractNameVisible")
  el-form
    el-form-item(label="Название договора")
      el-input(v-model="contractName")
  el-button(plain @click="() => changeContractName()") Сохранить
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import type { UploadInstance, UploadFile } from 'element-plus';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon
} from '@element-plus/icons-vue';
import { useDocumentsStore } from '@/store/documents';

const documentsStore = useDocumentsStore();
const uploadRef = ref<UploadInstance>();
const contractName = ref('');
const changeContractNameVisible = ref(false);

const contractSigningRequired = computed(() => {
  return documentsStore.contract?.signed === false;
});

const handleSelfEmploymentCertUpload = (uploadFile: UploadFile) => {
  documentsStore.uploadSelfEmploymentCert(uploadFile.raw as File)
    .then(documentsStore.getCertificate);
}

const handleContractUpload = (uploadFile: UploadFile) => {
  documentsStore.uploadContract(uploadFile.raw as File)
    .then(documentsStore.getContract);
}

const changeContractName = () => {
  documentsStore.updateContractName(contractName.value)
    .then(() => changeContractNameVisible.value = false);
}

const _requisitesTemplate = { edit: false, actualValue: '', value: '' }

const requisites = ref({
  receiver: { label: 'ФИО получателя', ..._requisitesTemplate },
  inn: { label: 'ИНН', ..._requisitesTemplate },
  bankAccountNumber: { label: 'Номер счета', ..._requisitesTemplate },
  bankIdentificationCode: { label: 'БИК', ..._requisitesTemplate },
  receiverBank: { label: 'Банк-получатель', ..._requisitesTemplate },
  correspondentAccount: { label: 'Корр. счет', ..._requisitesTemplate },
  bankInn: { label: 'ИНН банка', ..._requisitesTemplate },
  bankKpp: { label: 'КПП банка',  ..._requisitesTemplate },
  registrationAddress: { label: 'Адрес регистрации', ..._requisitesTemplate },
  email: { label: 'Email', ..._requisitesTemplate },
  phone: { label: 'Телефон', ..._requisitesTemplate },
});

const isRequisitesChanged = computed(() => {
  let changed = false;

  Object.entries(requisites.value).forEach(([k, v]) => {
    if (v.actualValue != v.value) {
      changed = true;
    }
  });

  return changed;
});

const initRequisites = () => {
  Object.entries(requisites.value).forEach(([k, v]) => {
    // @ts-ignore
    const actualValue = documentsStore.requisites[k] || '';

    const obj = { actualValue, value: actualValue, edit: false };

    // @ts-ignore
    requisites.value[k] = { ...v, ...obj };
  });
}

const updateRequisites = () => {
  const r = requisites.value;

  documentsStore.updateRequisites({
    receiver: r.receiver.value,
    inn: r.inn.value,
    bankAccountNumber: r.bankAccountNumber.value,
    bankIdentificationCode: r.bankIdentificationCode.value,
    receiverBank: r.receiverBank.value,
    correspondentAccount: r.correspondentAccount.value,
    bankInn: r.bankInn.value,
    bankKpp: r.bankKpp.value,
    registrationAddress: r.registrationAddress.value,
    email: r.email.value,
    phone: r.phone.value,
  })
    .then(initRequisites);
}

const update = () => {
  documentsStore.getRequisites()
    .then(initRequisites);

  documentsStore.getDocuments()
    .then(() => contractName.value = documentsStore.contract?.name ?? '');
}

update();
</script>
