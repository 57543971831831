<template lang="pug">
el-space
  el-tooltip(
    v-if="tasksStore.isActionAvailable(props.entry, Actions.DECLINE_TASK_ENTRY)"
    content="Отклонить"
  )
    el-button(
      circle
      type="danger"
      :icon="CloseIcon"
      @click="() => handleDeclineClick()"
  )
  el-tooltip(
    v-if="tasksStore.isActionAvailable(props.entry, Actions.CONFIRM_TASK_ENTRY)"
    content="Принять"
  )
    el-button(
      circle
      type="success"
      :icon="CheckIcon"
      @click="handleConfirmClick()"
    )
</template>

<script setup lang="ts">
import { Close as CloseIcon, Check as CheckIcon } from '@element-plus/icons-vue'
import { useTasksV2Store } from '@/store/tasks_v2'
import type { TaskEntry } from '@/models/tasks_v2'
import { Actions } from '@/utils/actions'

const props = defineProps<{
  entry: TaskEntry
}>()
const emit = defineEmits(['updated'])

const tasksStore = useTasksV2Store()

const handleDeclineClick = () => {
  tasksStore.declineTaskEntry(props.entry.taskUid, props.entry.uid)
    .then(() => emit('updated'))
}

const handleConfirmClick = () => {
  tasksStore.confirmTaskEntry(props.entry.taskUid, props.entry.uid)
    .then(() => emit('updated'))
}
</script>
