export const TASK_STATUS_ERROR = -20
export const TASK_STATUS_PENDING = -10
export const TASK_STATUS_READY = 0
export const TASK_STATUS_IN_PROGRESS = 10
export const TASK_STATUS_AWAITING_CONFIRMATION = 20
export const TASK_STATUS_DECLINED = 30
export const TASK_STATUS_CONFIRMED = 40
export const TASK_STATUS_COMPLETED = 50

export const taskStatusText = {
    [TASK_STATUS_ERROR]: 'ошибка при создании',
    [TASK_STATUS_PENDING]: 'в обработке',
    [TASK_STATUS_READY]: 'готова к разметке',
    [TASK_STATUS_IN_PROGRESS]: 'в разметке',
    [TASK_STATUS_AWAITING_CONFIRMATION]: 'ожидает подтверждения',
    [TASK_STATUS_DECLINED]: 'отклонена',
    [TASK_STATUS_CONFIRMED]: 'подтверждена',
    [TASK_STATUS_COMPLETED]: 'завершена',
}

export const textTypeByTaskStatus: { [k: number]: 'danger' | 'info' | 'primary' | 'success' } = {
    [TASK_STATUS_ERROR]: 'danger',
    [TASK_STATUS_PENDING]: 'info',
    [TASK_STATUS_READY]: 'info',
    [TASK_STATUS_IN_PROGRESS]: 'info',
    [TASK_STATUS_AWAITING_CONFIRMATION]: 'primary',
    [TASK_STATUS_DECLINED]: 'danger',
    [TASK_STATUS_CONFIRMED]: 'success',
    [TASK_STATUS_COMPLETED]: 'success',
}

export const ENTRY_STATUS_READY = 0
export const ENTRY_STATUS_PAUSED = 5
export const ENTRY_STATUS_IN_PROGRESS = 10
export const ENTRY_STATUS_AWAITING_CONFIRMATION = 20
export const ENTRY_STATUS_DECLINED = 30
export const ENTRY_STATUS_CONFIRMED = 40
export const ENTRY_STATUS_COMPLETED = 50

export const entryStatusText = {
  [ENTRY_STATUS_READY]: 'готов к разметке',
  [ENTRY_STATUS_PAUSED]: 'на паузе',
  [ENTRY_STATUS_IN_PROGRESS]: 'в разметке',
  [ENTRY_STATUS_AWAITING_CONFIRMATION]: 'ожидает подтверждения',
  [ENTRY_STATUS_DECLINED]: 'отклонено',
  [ENTRY_STATUS_CONFIRMED]: 'подтверждено',
  [ENTRY_STATUS_COMPLETED]: 'завершено',
}

export const textTypeByEntryStatus: { [k: number]: 'danger' | 'info' | 'primary' | 'success' } = {
  [ENTRY_STATUS_READY]: 'info',
  [ENTRY_STATUS_PAUSED]: 'info',
  [ENTRY_STATUS_IN_PROGRESS]: 'info',
  [ENTRY_STATUS_AWAITING_CONFIRMATION]: 'primary',
  [ENTRY_STATUS_DECLINED]: 'danger',
  [ENTRY_STATUS_CONFIRMED]: 'success',
  [ENTRY_STATUS_COMPLETED]: 'success',
}
