import { defineStore } from 'pinia'
import axios from '@/utils/axios'
import type {
  Report,
  ReportRequest,
  ReportResponse,
  ReportByUser,
  TypeReport
} from '@/models/reports'

interface State {
  page?: number
  perPage?: number
  since?: number
  to?: number
  reports: Report[]
  total: number
  reportsByUserId: {[key: number]: ReportByUser}
  typeReports: TypeReport[]
}

export const useReportsV2Store = defineStore('reports-v2', {
  state: (): State => ({
    reports: [],
    total: 0,
    reportsByUserId: {},
    typeReports: [],
  }),

  actions: {
    async get(params: ReportRequest) {
      const { data } = await axios.get<ReportResponse>('/v2/report/', { params })

      this.$patch(data)
      this.reports = data.results
    },

    async getByUserId(userId: number, params: ReportRequest) {
      const { data } = await axios.get<ReportByUser>(
        `/v2/report/${userId}`,
        { params }
      )

      this.reportsByUserId[userId] = data
    },

    async getByTypes(params: ReportRequest) {
      const { data } = await axios.get<{ results: TypeReport[] }>('v2/type-report/', { params })

      this.typeReports = data.results
    },

    getExcelReportLink(params: ReportRequest) {
      params = { ...params, type: 'excel' }
      return axios.getUri({ url: '/v2/report', params })
    },
  }
})
