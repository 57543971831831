import { defineStore } from 'pinia'
import type { Option, Period, DataEntry, StatRequest, StatResponse, Metric } from '@/models/stat'
import axios from '@/utils/axios'

interface State {
  data: { label: string, data: DataEntry[] }[]
}

export const useStatStore = defineStore('stat', {
  state: (): State => ({
    data: [],
  }),

  actions: {
    async getStat(o: Option, p: Period, m: Metric) {
      this.$state.data = []
      const dateRanges = getDateRanges(p)
      const promises: Promise<StatResponse>[] = []

      dateRanges.forEach(dateRange => {
        promises.push(
          axios
            .get<StatResponse>(
              '/v2/stat', {
                params: {
                  since: dateRange.start.getTime() / 1000,
                  to: dateRange.end.getTime() / 1000,
                  statType: o,
                  metricType: m,
                } as StatRequest,
            })
            .then(data => data.data),
        )
      })

      await Promise.all(promises)
        .then(data => {
          dateRanges.forEach((dateRange, i) => {
            this.$state.data.push({
              label: dateRange.start.toLocaleDateString(),
              data: data[i].stat,
            })
          })
        })
        .catch(() => {})
    },
  },
})

const getDateRanges = (p: Period): { start: Date, end: Date }[] => {
  const ranges: { start: Date, end: Date }[] = []

  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)
  today.setMilliseconds(0)

  if (p === 'day') {
    [7, 6, 5, 4, 3, 2, 1, 0].forEach(offset => {
      const start = new Date(today)
      const end = new Date(today)

      start.setDate(today.getDate() - offset)
      end.setDate(today.getDate() - offset + 1)

      ranges.push({ start, end })
    })
  } else if (p === 'week') {
    [4, 3, 2, 1, 0].forEach(offset => {
      const start = new Date(today)
      const end = new Date(today)

      start.setDate(today.getDate() - (today.getDay() - 1) - offset * 7)
      end.setDate(today.getDate() - (today.getDay() - 1) - offset * 7 + 7)

      ranges.push({ start, end })
    })
  } else if (p === 'month') {
    [4, 3, 2, 1, 0].forEach(offset => {
      const start = new Date(today)
      start.setDate(1)
      start.setMonth(today.getMonth() - offset)

      const end = new Date(start)
      end.setMonth(start.getMonth() + 1)

      ranges.push({ start, end })
    })
  }

  return ranges
}
