<template lang="pug">
el-dialog(v-model="createTaskDialogVisible" :align-center="true" title="Создание задачи")
    CreateTaskForm(@created="handleCreated()")
el-dialog(v-model="showTaskDialogVisible" width="75%" :align-center="true" title="Задача")
    TaskDetails(:taskUID="selectedTaskUID")

el-card
    el-form(:inline="true")
        el-form-item
            el-button(
                v-if="authStore.isAdmin"
                :icon="Edit"
                @click="handleCreateButtonClick"
            ) Создать

        el-form-item(style="width: 10%")
            el-select(placeholder="Тип" v-model="params.type" @change="getTasks" clearable)
                el-option(v-for="_type in tasksStore.types" :label="_type.name" :value="_type.key" align="center")

        el-form-item(style="width: 10%")
            el-select(placeholder="Статус" v-model="params.status" @change="getTasks" clearable)
                el-option(v-for="(label, value) in statuses.taskStatusText" :label="label" :value="value")

        el-form-item(style="width: 15%")
            el-select(placeholder="Приоритет" v-model="params.priority" @change="getTasks" clearable)
                el-option(
                    v-for="(label, value) in priorities.taskPriorityLabel"
                    :label="label"
                    :value="value"
                    align="center"
                )

        el-form-item
            el-date-picker(
                v-model="dateRange"
                type="daterange"
                format="DD.MM.YYYY"
                range-separator="—"
                value-format="x"
                @change="applyDateRange"
                clearable
            )

el-card
    el-table(:data="tasksStore.tasks" v-loading="loading" table-layout="auto")
        el-table-column(align="center" v-slot="scope: { row: Task }")
            el-button(
                v-if="scope.row.performer || authStore.isAdmin"
                round
                :disabled="scope.row.status <= statuses.TASK_STATUS_PENDING && !authStore.isAdmin"
                @click="handleOpenButtonClick(scope.row)"
            ) Открыть
            el-button(
                v-if="tasksStore.isActionAvailable(scope.row, Actions.PERFORM)"
                round
                :disabled="scope.row.status <= statuses.TASK_STATUS_PENDING"
                @click="handlePerformButtonClick(scope.row)"
            ) Выполнить
        el-table-column(prop="type.name" label="Тип" align="center")
        el-table-column(prop="status" label="Статус" align="center" v-slot="scope: { row: Task }")
            el-text(:type="statuses.textTypeByTaskStatus[scope.row.status]")
                | {{ statuses.taskStatusText[scope.row.status] }}
        el-table-column(prop="priority" label="Приоритет" align="center" v-slot="scope: { row: Task }")
            | {{ priorities.taskPriorityLabel[scope.row.priority] }}
        el-table-column(prop="performer" label="Исполнитель" align="center" v-slot="scope: { row: Task }")
            | {{ scope.row.performer || '—' }}
        el-table-column(prop="createdBy" label="Создатель" align="center")

    el-pagination.center(
        layout="total, prev, pager, next, sizes"
        :total="tasksStore.total"
        v-model:current-page="params.page"
        v-model:page-size="params.perPage"
        :page-sizes="[10, 30, 50, 100]"
        @change="getTasks"
    )

</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Edit } from '@element-plus/icons-vue'

import CreateTaskForm from '@/components/TasksV2/CreateTaskForm.vue'
import TaskDetails from '@/components/TasksV2/TaskDetails.vue'

import { useTasksV2Store } from '@/store/tasks_v2'
import { useAuthStore } from '@/store/auth'

import * as statuses from '@/utils/statuses'
import * as priorities from '@/utils/priorities'
import { Actions } from '@/utils/actions'
import type { Task, TasksListRequest } from '@/models/tasks_v2'


const loading = ref(false)
const dateRange = ref<number[] | undefined[]>([undefined, undefined])
const params = ref<TasksListRequest>({
    page: 1,
    perPage: 100,
    type: undefined,
    orderBy: undefined,
    priority: undefined,
    status: undefined,
    since: undefined,
    to: undefined,
})
const createTaskDialogVisible = ref(false)
const showTaskDialogVisible = ref(false)
const selectedTaskUID = ref<string>('')
const authStore = useAuthStore()
const tasksStore = useTasksV2Store()


const handleCreated = () => {
    createTaskDialogVisible.value = !createTaskDialogVisible.value
    getTasks()
}

const handleCreateButtonClick = () => {
    tasksStore.getUsers()
    createTaskDialogVisible.value = !createTaskDialogVisible.value
}

const handleOpenButtonClick = (task: Task) => {
    selectedTaskUID.value = task.uid
    showTaskDialogVisible.value = true
}

const handlePerformButtonClick = (task: Task) => {
    tasksStore.setTaskPerformer(task.uid)
}

const applyDateRange = () => {
    params.value.since = dateRange.value[0]

    dateRange.value[1]
    && (params.value.to = dateRange.value[1] + 1000 * 60 * 60 * 24 - 1)
    || (params.value.to = undefined)

    getTasks()
}

const getTasks = () => {
    loading.value = true
    tasksStore.getTasks(params.value)
        .finally(() => loading.value = false)
}

const getTypes = () => {
    tasksStore.getTaskTypes()
}

getTypes()
getTasks()
</script>
