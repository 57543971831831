import type { ActionKey } from '@/models/tasks_v2'

export const Actions: {[k in string]: ActionKey } = {
  PERFORM: 'Perform',
  REPLACE_FILE: 'ReplaceFile',
  DEFECT: 'Defect',
  NOT_DEFECT: 'NotDefect',
  UPLOAD_RESULT: 'UploadResult',
  CONFIRM_TASK_ENTRY: 'ConfirmTaskEntry',
  DECLINE_TASK_ENTRY: 'DeclineTaskEntry',
  PAYMENT: 'Payment',
  VALIDATION_PAYMENT: 'ValidationPayment',
}
