import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from '@/App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// @ts-ignore
import locale from 'element-plus/dist/locale/ru.mjs';
import router from '@/router';
import dayjs from "dayjs";
import 'dayjs/locale/ru';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import * as Sentry from '@sentry/vue';

dayjs.extend(localizedFormat)
dayjs.locale('ru')

const pinia = createPinia();
const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(ElementPlus, { locale });

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1

})

app.mount('#app');
