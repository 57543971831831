<template lang="pug">
el-table(:data="reportsStore.typeReports")
  el-table-column(prop="label" label="Тип")
  el-table-column(prop="createdTasks" label="Задач")
  el-table-column(prop="createdEntries" label="Исследований")
  el-table-column(prop="createdEntriesDefected" label="Бракованных")
  el-table-column(prop="performedEntries" label="Размечено")
  el-table-column(prop="validatedEntries" label="Провалидировано")
  el-table-column(prop="confirmedEntries" label="Подтверждено")
  el-table-column(prop="declinedEntries" label="Отклонено")
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useReportsV2Store } from '@/store/reports_v2'

const props = defineProps<{
  since: number
  to: number
}>()

const params = ref({
  page: 1,
  perPage: 100,
  since: props.since,
  to: props.to,
})

watch(
  () => props,
  (_) => update(),
  { deep: true }
)

const reportsStore = useReportsV2Store()
const loading = ref(false)

const update = () => {
  loading.value = true

  params.value.since = props.since
  params.value.to = props.to

  reportsStore.getByTypes(params.value)
    .finally(() => loading.value = false)
}

update()
</script>
