<template lang="pug">
el-dialog(v-model="dialogVisibleLocal" title="Задача" @close="closeModal" width="min-content" align-center=true)
  div(v-loading="loading")
    el-descriptions(direction="horizontal" :column="2" border=true)
      el-descriptions-item(label="Тип" align="left") {{ task.type.name }}
      el-descriptions-item(label="UID" align="left") {{ task.uid }}
      el-descriptions-item(label="Статус" align="left") {{ taskStatuses[task.status] }}
      el-descriptions-item(label="Приоритет" align="left") {{ priorities[task.priority] }}
      el-descriptions-item(label="Когда и кем создана" align="left") {{ new Date(task.createdAt * 1e3).toLocaleString('ru-RU') }} ({{ task.createdBy }})
      el-descriptions-item(label="Исполнитель" align="left") {{ task.performer }}
      el-descriptions-item(label="Файл" align="left" width="120")
        el-link(type="primary" :href="task.file.url" target="_blank")
          | {{ task.file.name  }}
          br
          | ({{ filesize(task.file.size) }})
      el-descriptions-item(label="Описание" align="left") {{ task.description || '--' }}
      el-descriptions-item(label="Количество исследований" width="220") {{ task.researchCount || '--' }}
      el-descriptions-item(label="Не размеченные исследования" width="220") {{ task.unusableCount || '--' }}
      el-descriptions-item(
        label="Стоимость"
        v-if="task.researchCount"
      )
        el-text {{ task.price.toLocaleString() }} ₽
        el-text(v-if="task.bonusPrice" style="color:var(--el-color-success);") {{ ` + ${task.bonusPrice.toLocaleString()} ₽ бонусы` }}
        el-text(v-if="task.isPaid" style="color:var(--el-color-success);") &nbsp;(оплачено)
    br
    el-form(v-if="!authStore.isAdmin && task.status !== STATUS_CONFIRMED" :rules="rules")
      el-form-item(prop="unusable" label="Количество брака")
        el-space(direction="horizontal")
          el-input-number(
            v-model="form.unusable"
            placeholder="--"
            :min="0"
            :max="task.researchCount"
          )
          el-form-item(label-position="center")
            el-button(@click="setUnusableCount" :disabled="form.unusable === undefined") Сохранить

    el-card(
      header="Результаты"
      class="el-card__comment"
    )
      el-table(:data="tasksStore.taskResults" style="width: 100%")
        el-table-column(v-slot="scope" label="Статус" width="120") {{ resultStatuses[scope.row.status] }}
        el-table-column(v-slot="scope" label="Файл" width="160")
          el-link(type="primary" :href="scope.row.file.url" target="_blank")
            | {{ scope.row.file.name }} ({{ filesize(scope.row.file.size) }})
        el-table-column(prop="createdBy" label="Создан" width="180" header-align="center")
        el-table-column(prop="comment" width="220" label="Комментарий")
        el-table-column(v-slot="scope" label="Дата и время валидации" width="220")
          | {{ scope.row.validatedAt ? dayjs.unix(scope.row.validatedAt).format('lll') : '--'}}
        el-table-column(prop="rejectionReason" label="Причина отклонения" width="220")
    el-card(header="Комментарий" v-if="authStore.isValidator || Boolean(task.validatorComment)")
      el-input(
        type="textarea"
        :autosize="{ minRows: 5, }"
        resize="none"
        placeholder="Оставьте комментарий"
        input-style="font-size: 1.2rem;"
        v-model="validatorComment"
        v-if="authStore.isValidator"
      )
      el-text(style="font-size: 1.2rem;" v-else) {{ task.validatorComment }}
      div(style="margin-block-start: 1rem;")
        el-button(
          size="large"
          type="danger"
          :icon="CloseIcon"
          :disabled="task.status !== STATUS_CONFIRMED && task.status !== STATUS_AWAITING_CONFIRMATION"
          @click="decline"
          v-if="authStore.isValidator"
        ) Отклонить

        el-button(
          size="large"
          type="success"
          :icon="SelectIcon"
          :disabled="task.status !== STATUS_AWAITING_CONFIRMATION && task.status !== STATUS_DECLINED"
          @click="accept"
          v-if="authStore.isValidator"
        ) Принять
    el-card(header="Загрузка файла" v-if="task.status !== STATUS_CONFIRMED")
      el-row(justify="center")
        el-form(label-width="0px")
          el-form-item
            el-upload(
              drag
              ref="uploadRef"
              :limit="1"
              :on-exceed="handleExceed"
              :multiple="false"
              :auto-upload="false"
              v-model:file-list="files"
            )
              UploadIcon(style="height: 3em;")
              div(class="el-upload__text")
                span Перетащите файл сюда или&nbsp;
                  em нажмите для загрузки

          el-form-item
            el-input(v-model="comment" placeholder="Комментарий" type="textarea" :autosize="{minRows: 3}")

          el-form-item
            el-button(@click="upload" :disabled="!filesCount") Загрузить

    el-card(
      body-style="text-align: center;"
      v-if="task.status === STATUS_CONFIRMED"
    )
      el-text(type="success" size="large") Задача подтверждена

el-progress(
  v-if="uploadingInProgress"
  class="el-progress-upload-file"
  type="circle"
  :style="`z-index: ${elProgressZIndex};`"
  :width="100"
  :percentage="uploadPercent"
  :stroke-width="10"
  :status="uploadStatus"
)
</template>

<style>
.el-progress-upload-file {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
}
</style>

<script setup lang="ts">
import { Select as SelectIcon, Upload as UploadIcon, Close as CloseIcon } from '@element-plus/icons-vue';
import { computed, ref, watch } from 'vue';
import type { PropType } from 'vue';
import type { UploadProps, UploadInstance, UploadRawFile, UploadUserFile } from 'element-plus'
import { ElMessage, ElLoading } from 'element-plus';
import { useTasksStore } from '../../store/tasks';
import { useAuthStore } from '../../store/auth';
import type { TaskInfo } from '../../models';

import { filesize } from "filesize";
import dayjs from "dayjs";

const tasksStore = useTasksStore();
const authStore = useAuthStore();

const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
    required: false,
  },
  task: {
    type: Object as PropType<TaskInfo>,
    required: true
  },
});

const dialogVisibleLocal = ref(props.dialogVisible)

const form = ref<{unusable: undefined | number}>({
  unusable: props.task.unusableCount || undefined,
});

const rules = {
  unusable: [{
    required: true,
    trigger: 'blur',
    message: 'Укажите количество брака',
  }]
}

const priorities = {
  10: '🔥',
  20: '🔥🔥',
  30: '🔥🔥🔥'
}

const STATUS_AWAITING_PROCESSING = '-30'
const STATUS_UNPACKED = '-20'
const STATUS_UNPACKING_ERROR = '-10'
const STATUS_READY = '0'
const STATUS_IN_PROGRESS = 10
const STATUS_AWAITING_CONFIRMATION = 20
const STATUS_DECLINED = 30
const STATUS_CONFIRMED = 40

const taskStatuses = {
  '-30': 'Ожидает распаковки',
  '-20': 'Распакована',
  '-10': 'Ошибка распаковки',
  0: 'Готова к разметке',
  10: 'В процессе разметки',
  20: 'Ожидает подтверждения',
  30: 'Отклонено',
  40: 'Подтверждено',
}

const resultStatuses = {
  0: 'Загружен',
  20: 'Принят',
  '-10': 'Отклонен',
  '-20': 'Ошибка валидации',
}

const loading = ref(false);
const uploadRef = ref<UploadInstance>();
const uploadPercent = ref(0)
const uploadStatus = ref('')
const uploadingInProgress = ref(false)
const elProgressZIndex = ref(2010)
const files = ref<UploadUserFile[]>()
const filesCount = computed(() => files.value?.length)
const comment = ref<string>('');

const validatorComment = ref<string>(props.task?.validatorComment);

watch(
  () => uploadPercent.value,
  (percent) => percent === 100 ? (uploadStatus.value = 'success') : (uploadStatus.value = '')
)

const accept = () => {
  loading.value = true;

  tasksStore.accept(props.task.uid, validatorComment.value)
    .then(() => {
      ElMessage.success('Задача успешно принята.')
    })
    .catch(() => ElMessage.error('Произошла ошибка при обновлении статуса задачи'))
    .finally(() => {
      emit('updated');
      emit('updatedSelectedTask');
      loading.value = false;
    })
}

const decline = () => {
  loading.value = true;

  tasksStore.decline(props.task.uid, validatorComment.value)
    .then(() => {
      ElMessage.success('Статус задачи изменен.')
    })
    .catch(() => ElMessage.error('Произошла ошибка при обновлении статуса задачи'))
    .finally(() => {
      emit('updated');
      emit('updatedSelectedTask');
      loading.value = false;
    })
}


const handleExceed: UploadProps['onExceed'] = (files) => {
  uploadRef.value!.clearFiles()
  uploadRef.value!.handleStart(files[0] as UploadRawFile)
}


const upload = () => {
  const loading = ElLoading.service({
    fullscreen: true,
    lock: true,
    svgViewBox: '0, 0, 0, 0',
  })

  elProgressZIndex.value = Number(loading.$el.style.zIndex) + 1
  uploadingInProgress.value = true

  tasksStore.uploadResult(
    props.task.uid,
    files.value!.at(0)?.raw as File,
    comment.value,
    (percent) => uploadPercent.value = percent
  )
    .then(() => {
      ElMessage.success('Файл успешно загружен.');
      tasksStore.results(props.task.uid);
      uploadRef.value!.clearFiles();
      comment.value = '';
    })
    .finally(() => {
      uploadingInProgress.value = false
      loading.close()
      uploadPercent.value = 0
    })
}


const setUnusableCount = () => {
  if (form.value.unusable === undefined) {
    return
  }

  loading.value = true;
  tasksStore.setUnusable(
    props.task.uid,
    form.value.unusable
  )
    .then(() => {
      ElMessage.success('Указано количество брака.');
      emit('updatedSelectedTask');
    })
    .finally(() => loading.value = false)
}

const emit = defineEmits([ 'update:dialogVisible', 'updated', 'updatedSelectedTask' ]);
const closeModal = () => emit('update:dialogVisible', false);
</script>
