import { defineStore } from 'pinia';

import axios from '../utils/axios';
import {
    TaskInfo,
    TaskResultInfo,
    TaskType,
    UserInfo,
    ListParams,
    ListResponse,
    SetPaidRequestParams,
    File as FileModel,
} from '../models';

interface State {
    total: number;
    tasks: TaskInfo[],
    taskResults: TaskResultInfo[],
    typesList: TaskType[],
    usersList: UserInfo[],
}

const UPLOAD_TIMEOUT = 1000 * 60 * 30

export const useTasksStore = defineStore('tasks', {
    state: (): State => ({
        total: 0,
        tasks: [],
        taskResults: [],
        typesList: [],
        usersList: [],
    }),

    actions: {
        async list(params: ListParams) {
            const { data: { total, results } } =
                await axios.get<ListResponse<TaskInfo>>('tasks', { params });
            this.total = total;
            this.tasks = results;
        },

        async results(uid: string) {
            const { data } = await axios.get<TaskResultInfo[]>(`tasks/${uid}/results`);
            this.taskResults = data
        },

        async finalFile(uid: string): Promise<FileModel> {
            const { data } = await axios.get<FileModel>(`tasks/${uid}/final-file`)
            return data
        },

        async types() {
            const { data: { results } } =
                await axios.get<ListResponse<TaskType>>('tasks/types');
            this.typesList = results
        },

        async users(params: ListParams) {
            const { data: { results } } =
                await axios.get<ListResponse<UserInfo>>('tasks/users', { params });
            this.usersList = results
        },

        async getTask(uid: string) {
            const { data } = await axios.get<TaskInfo>(`tasks/${uid}`)
            return data
        },

        async accept(uid: string, comment: string) {
            const fd = new FormData()
            fd.append('validatorComment', comment ? comment.trim() : '')

            await axios.post(`tasks/${uid}/accept`, fd)
        },

        async decline(uid: string, comment: string) {
            const fd = new FormData()
            fd.append('validatorComment', comment ? comment.trim() : '')

            await axios.post(`tasks/${uid}/decline`, fd)
        },

        async setPaid(params: SetPaidRequestParams) {
            const fd = new FormData()
            fd.append('isPaid', String(params.isPaid))

            await axios.post(`tasks/${params.uid}/paid`, fd)
        },

        async setValidationPaid(params: SetPaidRequestParams) {
            const fd = new FormData()
            fd.append('isPaid', String(params.isPaid))

            await axios.post(`tasks/${params.uid}/validationPaid`, fd)
        },

        async create(
            type_key: string,
            usernames: Array<string>,
            priority: number,
            file: File,
            description: string,
            split_size: number,
            validationRequired: boolean,
            progressCallback: (percent: number) => void
            ) {
            const formData = new FormData();
            formData.append('typeKey', type_key);
            formData.append('priority', priority.toString());
            formData.append('validationRequired', validationRequired.toString())
            formData.append('file', file);

            if (usernames.length) {
                usernames.forEach(username => {
                    formData.append('usernames', username);
                });
            }
            if (description.length) {
                formData.append('description', description);
            }
            if (split_size) {
                formData.append('splitSize', split_size.toString());
            }

            return await axios.post<TaskInfo>(
                'tasks/',
                formData,
                {
                    timeout: UPLOAD_TIMEOUT,
                    onUploadProgress: (event) =>
                        progressCallback(Math.round((event.progress || 0) * 100))
                }
            )
        },

        async performer(uid: string) {
            return await axios.post<TaskInfo>(`tasks/${uid}/perform`)
        },

        async setUnusable(uid: string, unusable: number) {
            const formData = new FormData();
            formData.append('count', unusable.toString());
            return await axios.post<TaskInfo>(`tasks/${uid}/set_unusable_count`, formData)
        },

        async uploadResult(
            uid: string,
            file: File,
            comment: string,
            progressCallback: (percent: number) => void
        ) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('uid', uid);
            formData.append('comment', comment);

            await axios.post<TaskInfo>(
                `tasks/${uid}/upload`,
                formData,
                {
                    timeout: UPLOAD_TIMEOUT,
                    onUploadProgress: (event) =>
                        progressCallback(Math.round((event.progress || 0) * 100))
                }
            )
        },
    },
});
