<template lang="pug">
el-space(direction="vertical")
  el-radio-group(v-model="metric")
    el-radio-button(
      v-for="(v, k) in STAT_METRIC"
      :key="k"
      :label="k"
      @change="emit('metric-changed', k)"
    ) {{ v }}

  el-radio-group(v-model="option")
    el-radio-button(
      v-for="(v, k) in STAT_OPTION"
      :key="k"
      :label="k"
      @change="emit('option-changed', k)"
    ) {{ v }}

  el-radio-group(v-model="period")
    el-radio-button(
      v-for="(v, k) in STAT_PERIOD"
      :key="k"
      :label="k"
      @change="emit('period-changed', k)"
    ) {{ v }}
</template>

<script setup lang="ts">
import { STAT_OPTION, STAT_PERIOD, STAT_METRIC } from '@/models/stat'
import type { Option, Period, Metric } from '@/models/stat'

const props = defineProps<{
  option: Option
  period: Period
  metric: Metric
}>()

const option = ref(props.option)
const period = ref(props.period)
const metric = ref(props.metric)

const emit = defineEmits(['option-changed', 'period-changed', 'metric-changed'])
</script>
