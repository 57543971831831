export type Option = 'by-types' | 'by-performers' | 'personal';

const STAT_OPTION_BY_TYPES = 'by-types';
const STAT_OPTION_BY_PERFORMERS = 'by-performers';
const STAT_OPTION_PERSONAL = 'personal';

export const STAT_OPTION = {
  [STAT_OPTION_BY_TYPES]: 'По типам',
  [STAT_OPTION_BY_PERFORMERS]: 'По разметчикам',
  [STAT_OPTION_PERSONAL]: 'Персональная',
} as const;

export type Period = 'day' | 'week' | 'month';

const STAT_PERIOD_DAY = 'day';
const STAT_PERIOD_WEEK = 'week';
const STAT_PERIOD_MONTH = 'month';

export const STAT_PERIOD = {
  [STAT_PERIOD_DAY]: 'По дням',
  [STAT_PERIOD_WEEK]: 'По неделям',
  [STAT_PERIOD_MONTH]: 'По месяцам',
} as const;

export type Metric = 'count' | 'declined-percent' | 'supplemented-percent';

const STAT_METRIC_COUNT = 'count';
const STAT_METRIC_DECLINED_PERCENT = 'declined-percent';
const STAT_METRIC_SUPPLEMENTED_PERCENT = 'supplemented-percent'

export const STAT_METRIC = {
  [STAT_METRIC_COUNT]: 'Количество разметок',
  [STAT_METRIC_DECLINED_PERCENT]: 'Процент отклоненных',
  [STAT_METRIC_SUPPLEMENTED_PERCENT]: 'Процент доразметки'
} as const;

export type DataEntry = {
  label: string;
  count: number;
};

export interface StatRequest {
  since: number
  to: number
  statType: string
  metricType: Metric
}

export interface StatResponse {
  since: number
  to: number
  stat: { label: string, count: number }[]
}
