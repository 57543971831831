<template lang="pug">
TaskDetails(
    v-if="showTaskDialog"
    v-model:dialogVisible="showTaskDialog"
    @updated="update"
    @updatedSelectedTask="updateSelectedTask"
    :task="task"
)

el-card
    el-row(justify="end"): el-button(tag="a" :href="getExcelReportLink()") Экспорт в Excel
    el-table(
        :data="reportsStore.reports"
        style="width: 100%"
        v-loading="loading"
        @sort-change="setOrder"
        @expand-change="handleExpand"
    )
        el-table-column(
            type="expand"
            v-slot="scope"
        )
            br
            el-text
                b Детализация
            br
            br
            el-text(v-if="reportsStore.reportsDetails[scope.row.performerId]?.performedTasks.length") Разметил:
                br
                br
                el-table(
                    :data="reportsStore.reportsDetails[scope.row.performerId]?.performedTasks ?? []"
                    :row-key="String(scope.row.performerId)"
                    border
                    table-layout="auto"
                    header-align="center"
                )
                    el-table-column(prop="uid" label="UID задачи" v-slot="scope" width="30px")
                        el-button(type="primary" text @click="openTask(scope.row)" width="30px") {{ scope.row.uid.slice(0, 10) }}...
                    el-table-column(prop="type.name" label="Тип")
                    el-table-column(prop="researchCount" label="Количество" v-slot="scope") {{ scope.row.researchCount || '--' }}
                    el-table-column(prop="unusableCount" label="Не размечено" v-slot="scope") {{ scope.row.unusableCount || '--' }}
                    el-table-column(prop="status" label="Статус" v-slot="scope")
                        el-text(
                            type="danger"
                            v-if="scope.row.status == STATUS_DECLINED"
                        ) {{ taskStatuses[scope.row.status] }}
                        el-text(
                            type="success"
                            v-else-if="scope.row.status == STATUS_CONFIRMED"
                        ) {{ taskStatuses[scope.row.status] }}
                        el-text(
                            type="info"
                            v-else-if="scope.row.status == STATUS_AWAITING_CONFIRMATION"
                        ) {{ taskStatuses[scope.row.status] }}
                        el-text(
                            v-else
                        ) {{ taskStatuses[scope.row.status] }}
                    el-table-column(
                        label="Стоимость"
                        v-slot="scope"
                    ) {{ scope.row.price.toLocaleString() }} ₽
                    el-table-column(
                        label="Бонусы"
                        v-slot="scope"
                    )
                        el-text(v-if="scope.row.bonusPrice") {{ scope.row.bonusPrice.toLocaleString() }} ₽
                        el-text(v-else) --
                    el-table-column(label="Оплатить" v-slot="scope")
                        el-button(
                            v-if="scope.row.isPaid"
                            :icon="WalletFilled"
                            type="success"
                            disabled
                        ) Оплачено
                        el-button(
                            v-else-if="!scope.row.isPaid && [STATUS_CONFIRMED, STATUS_AWAITING_CONFIRMATION].includes(scope.row.status)"
                            :icon="WalletFilled"
                            @click="showConfirmPaidMsg(scope.row)"
                        ) Оплатить
                        el-button(
                            v-else-if="![STATUS_CONFIRMED, STATUS_AWAITING_CONFIRMATION].includes(scope.row.status)"
                            :icon="WalletFilled"
                            disabled
                        ) Оплатить
                br
                br
            el-text(v-if="reportsStore.reportsDetails[scope.row.performerId]?.validatedTasks.length") Провалидировал:
                br
                br
                el-table(
                    :data="reportsStore.reportsDetails[scope.row.performerId]?.validatedTasks ?? []"
                    border
                    table-layout="auto"
                    header-align="center"
                )
                    el-table-column(prop="uid" label="UID задачи" v-slot="scope" width="30px")
                        el-button(type="primary" text @click="openTask(scope.row)" width="30px") {{ scope.row.uid.slice(0, 10) }}...
                    el-table-column(prop="type.name" label="Тип")
                    el-table-column(prop="researchCount" label="Количество" v-slot="scope") {{ scope.row.researchCount || '--' }}
                    el-table-column(prop="unusableCount" label="Не размечено" v-slot="scope") {{ scope.row.unusableCount || '--' }}
                    el-table-column(prop="status" label="Статус" v-slot="scope")
                        el-text(
                            type="danger"
                            v-if="scope.row.status == STATUS_DECLINED"
                        ) {{ taskStatuses[scope.row.status] }}
                        el-text(
                            type="success"
                            v-else-if="scope.row.status == STATUS_CONFIRMED"
                        ) {{ taskStatuses[scope.row.status] }}
                        el-text(
                            type="info"
                            v-else-if="scope.row.status == STATUS_AWAITING_CONFIRMATION"
                        ) {{ taskStatuses[scope.row.status] }}
                        el-text(
                            v-else
                        ) {{ taskStatuses[scope.row.status] }}
                    el-table-column(
                        label="Стоимость"
                        v-slot="scope"
                    ) {{ scope.row.validationPrice?.toLocaleString() || '--' }} ₽
                    el-table-column(label="Оплатить" v-slot="scope")
                        el-button(
                            v-if="scope.row.isValidationPaid"
                            :icon="WalletFilled"
                            type="success"
                            disabled
                        ) Оплачено
                        el-button(
                            v-else-if="!scope.row.isValidationPaid"
                            :icon="WalletFilled"
                            @click="showConfirmValidationPaidMsg(scope.row)"
                        ) Оплатить
        el-table-column(
            prop="performer"
            label="Исполнитель"
        )
        el-table-column(
            prop="taskCount"
            label="Количество заданий"
            sortable="custom"
        )
        el-table-column(
            prop="amount"
            label="Сумма"
            sortable="custom"
            v-slot="scope"
        ) {{ scope.row.amount.toLocaleString() }} ₽
        el-table-column(
            prop="isPaid"
            label="Оплачено полностью"
            sortable="custom"
            v-slot="scope"
        )
            CircleCheckFilled(
                v-if="scope.row.isPaid"
                style="height: 1em;"
            )
            CircleClose(
                v-if="!scope.row.isPaid"
                style="height: 1em;"
            )
            el-text(
                v-if="scope.row.isPaid"
                type="success"
            ) &nbsp; {{ paymentStatuses[scope.row.isPaid] }}
            el-text(
                v-if="!scope.row.isPaid"
                type="danger"
            ) &nbsp; {{ paymentStatuses[scope.row.isPaid] }}
        el-table-column(v-slot="scope")
            el-button(
                :icon="WalletFilled"
                :type="scope.row.isPaid ? 'success' : ''"
                :disabled="scope.row.isPaid"
                @click="() => setFullPaid(scope.row)"
            ) Оплатить все
    el-pagination.center(
        layout="total, prev, pager, next, sizes"
        :total="reportsStore.total"
        v-model:current-page="params.page"
        v-model:page-size="params.perPage"
        :page-sizes="[10, 30, 50, 100]"
        @current-change="update"
        @size-change="update"
    )
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { CircleCheckFilled, CircleClose, WalletFilled } from '@element-plus/icons-vue';

import type { ReportInfo, TaskInfo, ReportsListQueryParams, Period } from '../models';
import TaskDetails from './Tasks/Task.vue';
import { useReportsStore } from '../store/reports'
import { useTasksStore } from '../store/tasks'

const reportsStore = useReportsStore();
const tasksStore = useTasksStore();

const loading = ref(false);
const showTaskDialog = ref(false);
const task = ref<TaskInfo>();

const props = defineProps<{
    params: ReportsListQueryParams
}>()

watch(
    () => props.params,
    (periodStart: ReportsListQueryParams) => update(),
    { deep: true }
)

const paymentStatuses = {
    false: 'Не оплачено',
    true: 'Оплачено',
}

const STATUS_AWAITING_PROCESSING = '-30'
const STATUS_UNPACKED = '-20'
const STATUS_UNPACKING_ERROR = '-10'
const STATUS_READY = '0'
const STATUS_IN_PROGRESS = 10
const STATUS_AWAITING_CONFIRMATION = 20
const STATUS_DECLINED = 30
const STATUS_CONFIRMED = 40

const taskStatuses = {
    '-30': 'Ожидает распаковки',
    '-20': 'Распакована',
    '-10': 'Ошибка распаковки',
    0: 'Готова к разметке',
    10: 'В процессе разметки',
    20: 'Ожидает подтверждения',
    30: 'Отклонено',
    40: 'Подтверждено',
};

let previousExpandedRowsCount: number = 0

const setOrder = ({prop, order}: {prop: string, order: string}) => {
    order === null ? delete(props.params.orderBy) : props.params.orderBy = `${order === 'ascending' ? '' : '-'}${prop}`
    update()
}

const showConfirmPaidMsg = (target: TaskInfo) => {
    ElMessageBox.confirm(
        'Установить статус "оплачено"?',
        {
            confirmButtonText: 'Да',
            cancelButtonText: 'Отмена',
        }
    )
    .then(() => {
        tasksStore.setPaid({
            uid: target.uid,
            isPaid: true,
        }).then(() => {
            ElMessage.success('Установлен статус "оплачено"')
            updateDetails(target.performerId)
        })

    })
    .catch(e => e !== 'cancel' && Promise.reject(e))
    .catch(e => ElMessage.error('Ошибка выставления статуса оплаты'))
}

const showConfirmValidationPaidMsg = (target: TaskInfo) => {
    ElMessageBox.confirm(
        'Установить статус "оплачено"?',
        {
            confirmButtonText: 'Да',
            cancelButtonText: 'Отмена',
        }
    )
    .then(() => {
        tasksStore.setValidationPaid({
            uid: target.uid,
            isPaid: true,
        }).then(() => {
            ElMessage.success('Установлен статус "оплачено"')
            updateDetails(target.validatedBy)
        })
    })
    .catch(e => e !== 'cancel' && Promise.reject(e))
    .catch(e => ElMessage.error('Ошибка выставления статуса оплаты'))
}

const setFullPaid = async (target: ReportInfo) => {
    loading.value = true
    let success = true

    if (!reportsStore.reportsDetails[target.performerId]) {
        await reportsStore.getReportTasks(target.performerId, props.params)
    }

    const { performedTasks, validatedTasks } = reportsStore.reportsDetails[target.performerId]

    for (const task of performedTasks) {
        await tasksStore.setPaid({ uid: task.uid, isPaid: true })
            .catch(e => success = false)
    }

    for (const task of validatedTasks) {
        await tasksStore.setValidationPaid({ uid: task.uid, isPaid: true })
            .catch(e => success = false)
    }

    loading.value = false

    if (success) {
        ElMessage.success('Установлен статус "оплачено"')
    } else {
        ElMessage.error('Ошибка')
    }

    update()
}

const handleExpand = (expanded: ReportInfo, expandedRows: Array<ReportInfo>) => {
    !previousExpandedRowsCount && (previousExpandedRowsCount = expandedRows.length)

    if (expandedRows.length < previousExpandedRowsCount) {
        previousExpandedRowsCount = expandedRows.length
        return
    }

    loading.value = true

    reportsStore
        .getReportTasks(expanded.performerId, props.params)
        .then(() => previousExpandedRowsCount = expandedRows.length)
        .catch(e => ElMessage.error('Ошибка при получении детализации'))
        .finally(() => loading.value = false)
}

const openTask = (target: TaskInfo) => {
    loading.value = true;

    tasksStore.getTask(target.uid)
        .then((t) => task.value = t)
        .then(() => tasksStore.results(target.uid))
        .then(() => showTaskDialog.value = true)
        .catch(() => ElMessage.error('Произошла ошибка при открытии задачи'))
        .finally(() => loading.value = false);
};

const getExcelReportLink = () => {
    return reportsStore.getExcelReportLink(props.params)
}

const updateSelectedTask = () => {
    loading.value = true;
    tasksStore.getTask(task.value!.uid)
    .then((t) => task.value = t)
    .catch(() => ElMessage.error('Произошла ошибка при обновлении задачи'))
    .finally(() => loading.value = false)
}

const updateDetails = (performerId: number) => {
    loading.value = true

    reportsStore
        .getReportTasks(performerId, props.params)
        .catch(e => ElMessage.error('Ошибка при получении детализации'))
        .finally(() => loading.value = false)
}

const update = () => {
    previousExpandedRowsCount = 0
    loading.value = true;

    reportsStore
    .list(props.params)
    .catch(() => ElMessage.error('Ошибка при получении отчетов'))
    .finally(() => loading.value = false);
};

update();
</script>
