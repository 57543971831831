<template>
<el-card id="settings-card" header="Настройки">
  <el-form v-if="notificationsStore.configured === true">
    <el-form-item label="Время доставки уведомлений со звуком" style="align-items: center">
      <el-space>
        <el-input
          v-model="notificationsStore.soundTimeStart"
          placeholder="HH:MM"
          :maxlength="5"
          @input="formChanged = true"
        />
        <p>—</p>
        <el-input
          v-model="notificationsStore.soundTimeEnd"
          placeholder="HH:MM"
          :maxlength="5"
          @input="formChanged = true"
        />
      </el-space>
    </el-form-item>
    <el-form-item label="Уведомлять о событиях">
      <el-select
        v-model="notificationsStore.subscribedEvents"
        multiple
        @change="formChanged = true"
      >
        <el-option
          v-for="e in notificationsStore.events"
          :value="e.key"
          :label="e.label"
        />
      </el-select>
    </el-form-item>
  </el-form>

  <p v-else-if="notificationsStore.configured === false">
    Чтобы настройки стали доступны,
    перейдите по
    <el-link
      :href="link"
      type="primary"
      target="_blank"
    >
      ссылке <img src="@/assets/new-tab-icon.svg" />
    </el-link>
    и нажмите кнопку "Start"
  </p>

  <el-row justify="end">
    <el-button
      :disabled="!formChanged"
      plain
      @click="saveChanges"
    >
      Сохранить
    </el-button>
  </el-row>
</el-card>
</template>

<script setup lang="ts">
import { useNotificationsStore } from '@/store/notifications'
import { ElLoading, ElNotification } from 'element-plus';

const notificationsStore = useNotificationsStore()
const link = ref('')
const formChanged = ref(false)

const saveChanges = () => {
  notificationsStore.saveNotificationsSettings()
    .then(() => ElNotification.success({ title: 'Настройки сохранены' }))
    .catch(e => {
      console.log(e)
      ElNotification.error({ title: 'Ошибка' })
    })
}

onMounted(() => {
  const loadingService = ElLoading.service({
    target: '#settings-card',
  })

  notificationsStore.getNotificationsSettings()
    .then(() => {
      if (!notificationsStore.configured) {
        return notificationsStore.getConfigurationLink()
      }
    })
    .then(l => link.value = l ?? '')
    .then(notificationsStore.getAvailableEvents)
    .finally(loadingService.close)
})
</script>
