<template lang="pug">
el-card#documents(header="Акты и чеки")
  el-row(style="margin-bottom: 10px; gap: 20px")
    el-button(
      plain
      @click="() => handleActCreate()"
    ) Создать акт
  el-table(:data="documentsStore.acts" v-loading="actsLoading")
    el-table-column(label="Период" v-slot="act: { row: Act }") {{ formatPeriod(act.row) }}
    el-table-column(align="center" label="Акт" v-slot="act: { row: Act }")
      el-space
        el-link(
          :href="act.row.actFile?.url"
          type="primary"
          style="text-align: left;"
        ) {{ act.row.actFile?.name.split('/').at(-1) }}
        el-button(
          v-if="!act.row.confirmed"
          plain
          @click="() => handleActUpdate(act.row)"
        ) Внести данные
    el-table-column(align="center" label="Чек" v-slot="act: { row: Act }")
      el-upload(
        v-if="!act.row.receiptFile && !act.row.confirmed"
        @change="(file) => handleActReceiptUpdate(file, act.row.uuid)"
        :show-file-list="false"
        :auto-upload="false"
      )
        el-button(plain) Загрузить
      el-space(v-else)
        el-link(
          :href="act.row.receiptFile?.url"
          type="primary"
          style="text-align: left;"
        ) {{ act.row.receiptFile?.name.split('/').at(-1) }}
        el-upload(
          v-if="!act.row.confirmed"
          @change="(file) => handleActReceiptUpdate(file, act.row.uuid)"
          :show-file-list="false"
          :auto-upload="false"
        )
          el-button(plain) Обновить

el-dialog(v-model="createActFormVisible")
  create-act-form(@created="handleActCreated")

el-dialog(v-model="updateActFormVisible")
  update-act-form(:act="updateActFormAct" @updated="handleActUpdated")
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { UploadFile } from 'element-plus';
import type { Act } from '@/models/documents';
import { useDocumentsStore } from '@/store/documents';
import CreateActForm from '@/components/Profile/CreateActForm.vue'
import UpdateActForm from '@/components/Profile/UpdateActForm.vue'

const documentsStore = useDocumentsStore();
const createActFormVisible = ref(false);
const updateActFormVisible = ref(false);
const updateActFormAct = ref<Act | undefined>();
const actsLoading = ref(false);

const handleActCreate = () => {
  createActFormVisible.value = true;
}

const handleActUpdate = (act: Act) => {
  updateActFormAct.value = act;
  updateActFormVisible.value = true;
}

const handleActReceiptUpdate = (uploadFile: UploadFile, uuid: String) => {
  documentsStore.updateActReceipt(uploadFile.raw as File, uuid)
    .then(documentsStore.getActs);
}

const handleActCreated = () => {
  createActFormVisible.value = false;
  updateActs();
}

const handleActUpdated = () => {
  updateActFormVisible.value = false;
  updateActs();
}

const formatPeriod = (act: Act) => {
  const [ps, pe] = [act.periodStart, act.periodEnd];

  if (ps && pe) {
    return `${new Date(ps * 1e3).toLocaleDateString()} — ${new Date(pe * 1e3).toLocaleDateString()}`;
  } else if (ps) {
    return `${new Date(ps * 1e3).toLocaleDateString()} — n/a`;
  } else if (pe) {
    return `n/a — ${new Date(pe * 1e3).toLocaleDateString()}`;
  } else {
    return 'n/a';
  }
}

const updateActs = () => {
  actsLoading.value = true;
  documentsStore.getActs()
    .finally(() => actsLoading.value = false);
}

updateActs();
</script>
